import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from 'containers/home-page'
import Gallery from 'containers/gallery-page'
import About from 'containers/about-page'
import Contact from 'containers/contact-page'
import Takearole from 'containers/takearole-page'
import Login from 'containers/takearole-page/login.js'
import Forgot from "containers/takearole-page/forgot.js"
import Generatecode from "containers/takearole-page/generatecode.js"


import Myprofile from 'containers/my-profile-page'
import Members from 'containers/members'
import Mydonations from "./containers/mydonations"
import Donatenow from "./containers/donatenow"
import Jobs from "./containers/job-page"





import AdminLogin from "./containers/admin/login"
import AdminHome from "./containers/admin/adminhome"
import Membershipcard from "./containers/admin/membershipcard"


import AdminHeader from "./containers/admin/adminHeader"
import MembersRequest from "./containers/admin/membershipRequest"
import MembersAdmin from "./containers/admin/admin-members"
import Adminmemberdetail from './containers/admin/admin-member-detail'
import Adminmemberapprovaldetail from './containers/admin/admin-member-approval-detail'
import Postjobsadmin from './containers/admin/post-job-admin'


import Checkmemberdonationbyadmin from "./containers/admin/check-all-member-donation"


import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Loader from 'components/loader'
const promise = loadStripe(
  'pk_test_51I8LmKHJD0f47leV7DsjlemqkokBOQlkxB2nkjQyQnRua6uCFZ9PFZzVXAcUMEoTBd5V4NySWYA8yoIrcelqSEZR002ab8FcFp',
)
const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router >
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>

          <Route path="/gallery">
            <Gallery />
          </Route>

          <Route path="/about">
            <About />
          </Route>

          <Route path="/contact">
            <Contact />
          </Route>

          <Route path="/membership">
            <Takearole />
          </Route>

          <Route path="/login">
            <Login />
          </Route>

          <Route path="/forgot">
            <Forgot />
          </Route>

          <Route path="/generatecode">
            <Generatecode />
          </Route>
          

          <Route path="/myprofile">
            <Myprofile />
          </Route>
          <Route path="/members">
            <Members />
          </Route>

          <Route path="/donate">
            <Elements stripe={promise}>
              <Donatenow />
            </Elements>
          </Route>

          
          <Route path="/mydonation">
            <Mydonations />
          </Route>

          <Route path="/jobs">
            <Jobs />
          </Route>
        

          {/* admin routes  */}
          <Route path="/admin">
            <AdminLogin />
          </Route>

          <Route path="/adminhome">
            <AdminHeader/>
            <AdminHome />
          </Route>

          <Route path="/memberrequests">
            <AdminHeader/>
            <MembersRequest />
          </Route>


          <Route path="/memberadmin">
            <AdminHeader/>
            <MembersAdmin />
          </Route>

          <Route path="/adminmemberdetail">
            <AdminHeader/>
            <Adminmemberdetail />
          </Route>

          <Route path="/adminmemberapprovaldetail">
            <AdminHeader/>
            <Adminmemberapprovaldetail />
          </Route>


        
          <Route path="/checkallmemberdonations">
            <AdminHeader/>
            <Checkmemberdonationbyadmin />
          </Route>

          <Route path="/postjobadmin">
            <AdminHeader/>
            < Postjobsadmin />
          </Route>

          <Route path="/membershipcard">
            <AdminHeader/>
            < Membershipcard />
          </Route>

        </Switch>
      </Router>
    </Suspense>
  )
}
export default Routes
