import React,{ useState,useEffect } from 'react'
import "./login.css"
import { Link,useHistory } from 'react-router-dom'


function AdminLogin() {
    const history = useHistory();
    const [email, setEmail] = useState('kambohwelfareanjumankarachi@gmail.com');
    const [password, setPassword] = useState('');



    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }, [])

    const signIn = e => {
        var adminLoginData = {
            email: email,
            password: password,
          }
           if(email === "kambohwelfareanjumankarachi@gmail.com" && password === "kambohkarachi1818"){
            localStorage.setItem('ADMIN', JSON.stringify(adminLoginData))
             history.push('/adminhome')
           }
           else{
               alert("Invalid Login Details")
           }

    }


    

    return (
        <div  className='login'>
        <Link className="logo" to='/'>
            <h1>kamboh</h1>
        </Link>

        <div className='login__container'>
            <h1>Sign-in</h1>

                <h5>E-mail</h5>
                <input value={email} type='text'  onChange={e => setEmail(e.target.value)} />

                <h5>Password</h5>
                <input type='password' value={password} onChange={e => setPassword(e.target.value)} />

                <button style={{color:"white"}} onClick={(e)=>signIn(e)} type='submit' className='login__signInButton'>Sign In</button>
            
        </div>
    </div>
    )
}

export default AdminLogin
