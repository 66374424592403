import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../../components/theme";
import Donation from "../../../assets/images/donation.png"
import { useStateValue } from '../../../components/StateProvider'
import { Link, useHistory, useLocation } from "react-router-dom";
import { BackServer } from "../../../Services"
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "130px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Adminmemberapprovaldetail(props) {
    const [memberdetail,setmemberdetail]=useState('')
    const classes = useStyles();
    const history = useHistory();
    // const [{ members }, dispatch] = useStateValue();
    const [{ members, getmembers, updatedmember }, dispatch] = useStateValue()


    useEffect(() => {
       
        setmemberdetail(history.location.state)

        //neww
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        var ADMIN = JSON.parse(localStorage.getItem('ADMIN'))
        if (!ADMIN) {
            history.push('/Admin')
        }


        var Members = JSON.parse(localStorage.getItem('members'))

        if (Members) {
            dispatch({
                type: 'SET_MEMBER',
                payload: Members,
            })
        }


        axios
            .get(`${BackServer}/data/getAllMembers`)
            .then((res) => {
                dispatch({
                    type: "GET_MEMBERS",
                    payload: res.data.allmembers
                })
            })
    

    }, [])

    const signoutMethod = () => {

        localStorage.removeItem("members");

        dispatch({
            type: "SET_MEMBER",
            payload: null,
        });

        history.push("/");

    };

    const MemberApproved = (Userid) => {

        // alert(user)

        var user = {
            userID: Userid
        }
        axios
            .post(`${BackServer}/data/updatemember`, user)
            .then((res) => {
                if (res && res.data && res.data.success) {
                    // window.location.reload();
                    history.push('/memberrequests')
                }
            })
    }

    const Memberreject = (Userid) => {

    
        axios
            .delete(`${BackServer}/data/deleteMemberRequest/${Userid}`,)
            .then((res) => {

                if (res.data.suc === true) {
                    history.push('/memberrequests')

                }
            })

    }

    

    return (

        <div class="container" style={{ marginTop: "70px" }}>
            <div class="main-body">


                <div class="row gutters-sm">
                    <div class="col-md-4 mb-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex flex-column align-items-center text-center">
                                    <img src={memberdetail && memberdetail.imageUrl} alt="Admin" class="rounded-circle" width="150" />

                                    <div class="mt-3">
                                        <h4>{memberdetail && memberdetail.firstname}</h4>
                                        <p class="text-secondary mb-1">{memberdetail && memberdetail.business}</p>
                                        <p class="text-muted font-size-sm">{memberdetail && memberdetail.caddress}</p>
                                    </div>
                                    <li style={{cursor:"pointer"}} onClick={() => history.push({pathname: '/checkallmemberdonations',state:memberdetail && memberdetail})}  class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0"><img src={Donation} width="30px" height="30px" /> Member Donation</h6>
                                </li>

                                </div>
                            </div>
                        </div>
                     
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-3">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Full Name</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.firstname + "," + memberdetail.fathername}
                                    </div>
                                </div>
                                <hr />

                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Membership Category</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.membershipcategory}
                                        <br/>
                                       membership fees : {memberdetail && memberdetail.membershipfees}
                                       <br/>

                                       Registration fees : {memberdetail && 500}
                                       <br/>

                                      paid Registration fees : {memberdetail && memberdetail.registrationfees}



                                    </div>
                                </div>

                                
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Email</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.email}
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Mobile</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.mobile}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Business</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.business}
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Current Address</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.caddress}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Permanent Address</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.paddress}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Office/Shop Address</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.oaddress}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Age</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.age}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Qualification</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.qualification}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Cnic</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.cnic}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Area</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.area}

                                    </div>
                                </div>
                                <hr />

                                {memberdetail && memberdetail.city &&
                                
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">City</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.city}

                                    </div>
                                </div>
                                }
                                <hr />

                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Sportman/Cricketer?</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.cricket === "true" ? "Yes" : "No"}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Accept Term And Condition</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.policy === "true" ? "Yes" : "No"}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Blood Group</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.bloodgroup}

                                    </div>
                                </div>
                                <hr />

                                <div class="row" >
                                    <div style={{marginTop:"30px"}} onClick={() => MemberApproved(memberdetail && memberdetail._id)} class="col-sm-3">
                                        <a style={{color:"white"}} class="btn btn-info " >APPROVE</a>
                                    </div>
                                    
                                    <div style={{marginTop:"30px"}} onClick={() => Memberreject(memberdetail && memberdetail._id)} class="col-sm-9">
                                        <a style={{color:"white"}} class="btn btn-danger" >reject</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    )
}


