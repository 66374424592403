


import React, { useState } from "react";
import styled from "styled-components";
import { colors, Media } from "./theme";
import { Container, Col } from "react-bootstrap";
import Plot from "../assets/images/plot.jpg";
import { gilroySemibold, gilroyBold, sourceSansPro, gilroyMediumItalic, gilroyExtrabold } from "./theme/fonts";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';


import march2021 from "../assets/images/march2021.jpg";
import march20212 from "../assets/images/march20212.jpg";
import march20213 from "../assets/images/march20213.jpg";
import march20214 from "../assets/images/march20214.jpg";
import march20215 from "../assets/images/march20215.jpg";

import march20191 from "../assets/images/march20191.jpg";
import march20192 from "../assets/images/march20192.jpg";

import march20171 from "../assets/images/march20171.jpg";
import march20172 from "../assets/images/march20172.jpg";
import march20173 from "../assets/images/march20173.jpg";

import march20151 from "../assets/images/march20151.jpg";
import march20152 from "../assets/images/march20152.jpg";
import march20153 from "../assets/images/march20153.jpg";
import march20154 from "../assets/images/march20154.jpg";




import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Carousel } from "react-bootstrap";
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        border: "2px solid red"
    },
    media: {
        height: 240,
    },
});

export default function Homesection5(props) {
    const classes = useStyles();
    const [imagesArray, setimagesArray] = useState(
        [
            { img: march2021, title: "GUESTS" },
            { img: march20212, title: "Special guest, Akhtar Rasool Kamboh taking oath from the officials of Kamboh Welfare Anjuman Karachi on March 23, 2021 at Kamboh Convention." },
            { img: march20213, title: "GUESTS" },
            { img: march20214, title: "Special guest, Akhtar Rasool Kamboh taking oath from Kamboh Welfare Anjuman Karachi youth on March 23, 2021 at Kamboh Convention." },
            { img: march20215, title: "Special guest, Akhtar Rasool Kamboh taking oath from Kamboh Welfare Anjuman Karachi youth on March 23, 2021 at Kamboh Convention." },
        ],
    )

    const [imagesArray2, setimagesArray2] = useState(
        [
            { img: march20191, title: "SOLIDARITY WITH GUESTS" },
            { img: march20192, title: "Convention Card" },
        ],
    )

    const [imagesArray3, setimagesArray3] = useState(
        [
            { img: march20171, title: "" },
            { img: march20172, title: "" },
            { img: march20173, title: "" },
        ],
    )


    const [imagesArray4, setimagesArray4] = useState(
        [
            { img: march20151, title: "Chief Guests" },
            { img: march20152, title: "" },
            { img: march20153, title: "kamboh welfare anjuman Karachi officials on stage" },
            { img: march20154, title: "International Kabbadi Team Player Ubaidullah Kamboh" },
        ],
    )

    const urlParam = window.location.pathname;


    return (
        <div style={{ marginTop: "-5%" }}>
            <Title1>
                WATCH OUR
            </Title1>
            <Title2>
                RECENT EVENTS
            </Title2>

            <Wrapper>
            <Grid container spacing={3}>

                <Grid item item xs={12} sm={3}>
                    <Card className={classes.root}>
                        <CardActionArea>
                            <Carousel interval={3000} controls={false} indicators={false}  >

                                {imagesArray && imagesArray.map((item, ind) => {
                                    return (
                                        <Carousel.Item>
                                            <CardMedia
                                                className={classes.media}
                                                image={item.img}
                                                title={item.title}
                                            />
                                        </Carousel.Item>
                                    )
                                })}

                            </Carousel>

                            <CardContent>
                                <Typography style={{ fontWeight: "bold", fontSize: "13px" }} gutterBottom variant="p" component="h6">
                                    Kamboh Convention 23rd March 2021
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                        <CardActions>

                            <Button onClick={() => window.open(`https://www.facebook.com/watch/live/?v=453697319399660&ref=watch_permalink`, '_blank')} style={{ height: "25px" }} variant="contained" color="secondary">
                                WATCH
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>


                <Grid item item xs={12} sm={3}>
                    <Card className={classes.root}>
                        <CardActionArea>
                            <Carousel interval={3000} controls={false} indicators={false}  >

                                {imagesArray2 && imagesArray2.map((item, ind) => {
                                    return (
                                        <Carousel.Item>
                                            <CardMedia
                                                className={classes.media}
                                                image={item.img}
                                                title={item.title}
                                            />
                                        </Carousel.Item>
                                    )
                                })}

                            </Carousel>

                            <CardContent>
                                <Typography style={{ fontWeight: "bold", fontSize: "13px" }} gutterBottom variant="p" component="h6">
                                    Kamboh Convention 24th Feb 2019
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                        <CardActions>

                            <Button onClick={() => window.open(`https://www.facebook.com/watch/live/?v=2192903241023029&ref=watch_permalink`, '_blank')} style={{ height: "25px" }} variant="contained" color="secondary">
                                WATCH
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>


                <Grid item item xs={12} sm={3}>
                    <Card className={classes.root}>
                        <CardActionArea>
                            <Carousel interval={3000} controls={false} indicators={false}  >

                                {imagesArray3 && imagesArray3.map((item, ind) => {
                                    return (
                                        <Carousel.Item>
                                            <CardMedia
                                                className={classes.media}
                                                image={item.img}
                                                title={item.title}
                                            />
                                        </Carousel.Item>
                                    )
                                })}

                            </Carousel>

                            <CardContent>
                                <Typography style={{ fontWeight: "bold", fontSize: "13px" }} gutterBottom variant="p" component="h6">
                                    Kamboh Convention 26th Feb 2017
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                        <CardActions>

                            <Button onClick={() => window.open(`https://www.facebook.com/kwakp/videos/2192903241023029/`, '_blank')} style={{ height: "25px" }} variant="contained" color="secondary">
                                WATCH
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item item xs={12} sm={3}>
                    <Card className={classes.root}>
                        <CardActionArea>
                            <Carousel interval={3000} controls={false} indicators={false}  >

                                {imagesArray4 && imagesArray4.map((item, ind) => {
                                    return (
                                        <Carousel.Item>
                                            <CardMedia
                                                className={classes.media}
                                                image={item.img}
                                                title={item.title}
                                            />
                                        </Carousel.Item>
                                    )
                                })}

                            </Carousel>

                            <CardContent>
                                <Typography style={{ fontWeight: "bold", fontSize: "13px" }} gutterBottom variant="p" component="h6">
                                    Kamboh Convention 22rd March 2015
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                        <CardActions>

                            <Button onClick={() => window.open(`https://www.facebook.com/kwakp/videos/?ref=page_internal`, '_blank')} style={{ height: "25px" }} variant="contained" color="secondary">
                                WATCH
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                </Grid>
            </Wrapper>

            <div style={{ height: "50px", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", paddingRight: "50px" }}>
                <Button onClick={() => window.open(`/gallery`)} variant="contained" color="secondary">
                    More
                </Button>
            </div>
        </div>
    );
}

const Wrapper = styled.div`
width:100%;
padding:20px;
padding-top:40px;
${Media("xlscreens")} {
  height: 100%;
}
${Media("tablet")} {
}
`;

const Title1 = styled.div`
width:100%;
display:flex;
justify-content:center;
padding-top:50px;
letter-spacing: -1px;
color:#9d9d9d;
font-weight:bold;
margin-top:6%;
font-family: ${gilroyMediumItalic} !important ;

${Media("xlscreens")} {
  height: 4vw;
}
${Media("tablet")} {
}
`;

const Title2 = styled.div`
width:100%;
display:flex;
justify-content:center;
font-family: ${gilroyExtrabold} !important ;
font-weight:bold;
line-height: -20px;
letter-spacing: -1.5px;
${Media("xlscreens")} {
  font-size: 4.166vw;
  margin: 0.2vw 0;
}
${Media("tablet")} {
  font-size: 38px;
  margin: 20px 0;
  br {
  }
}
`;

const Radiusdiv = styled.div`
 background-color:#f78d15;
 display:flex;
 justify-content:center;
 align-items:center;
 border-radius:50px;
 width:100px;
 height:100px;
font-family: ${gilroyExtrabold} !important ;
font-size:12px;
color:#ffffff;
font-weight:bold;
border:4px solid red;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const RadiusMain = styled.div`
 display:flex;
 justify-content:space-evenly;
 align-items:center;
 width:100%;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const Heading = styled.div`
font-size:13px;
font-weight:bold;
font-family: ${gilroyExtrabold} !important ;
margin-top:20px;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;



const CustomCol = styled(Col)`
margin-top:70px;

`;









