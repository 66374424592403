
// var Server = "http://localhost:3000"

var Server = "https://kamboh.com.pk"


// var BackServer ="http://localhost:8080"


var BackServer = "https://burgundy-agouti-sock.cyclic.app"



export { Server, BackServer }

