import React, { Fragment } from 'react'
import Header from './header'
import Footer from './footer'
import CreateGlobalStyle from './global-styles'
const Layout = ({ children }) => {
  return (
    <Fragment>
      <CreateGlobalStyle />
      <Header />
      <div id="main">{children}</div>
      <Footer />
    </Fragment>
  )
}
export default Layout
