import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Zubair from "../../assets/images/zubair.jpg"

import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../components/theme";
import { BackServer } from "../../Services"
import axios from 'axios'

import { useStateValue } from '../../components/StateProvider'
import { Link, useHistory, useLocation } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';

import job from "../../assets/images/job2.jpg"
import jobbg from "../../assets/images/jbg.jfif"

import './job.css'

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Jobs() {
    const classes = useStyles();
    const history = useHistory();

    const [{ getalljobs, members, getalljobssearch ,getmembers}, dispatch] = useStateValue();
    const [jobs, setjob] = useState(0);
    const [joboption, setjoboption] = useState('');


    const [jobtitle, settitle] = useState('');
    const [jobdesc, setdesc] = useState('');
    const [link, setlink] = useState('');
    const [jobcontact, setjobcontact] = useState('');



    const [searchdata, setsearchdata] = useState([]);







    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        if (!members) {
            history.push('/')
        }
        if (members) {
            axios
                .get(`${BackServer}/data/getAllMembers`)
                .then((res) => {
                    dispatch({
                        type: "GET_MEMBERS",
                        payload: res.data.allmembers
                    })
                })
        }

        axios
            .get(`${BackServer}/data/getAlljobs`)
            .then((res) => {

                dispatch({
                    type: "GET_JOBS",
                    payload: res.data.alljobs
                })
            })


    }, [])


    const postjob = () => {
        var jobData = {
            jobtitle: jobtitle,
            jobdesc: jobdesc,
            link: link,
            jobcontact: jobcontact,
            date: new Date().toLocaleDateString('de-DE'),
            joboption: joboption

        }
        if (jobtitle === "" || jobdesc === "") {
            alert("All fields are required")
        }

        else if (joboption === "") {
            alert("select job category")
        }

        else if (joboption === 'private job' && jobcontact === "") {
            alert("enter organization contact ")
        }

        else if (joboption === 'government' && link === "") {
            alert("enter govt job link")
        }

        else {


            if (members) {
                axios
                    .post(`${BackServer}/data/postjob`, jobData)
                    .then((res) => {
                        alert('successfully post')
                        window.location.reload();
                    })
            }
        }
    }

    const handlesearch = (event) => {

        var dataa = getalljobs;
        var newData = dataa.filter((f, i) => f.jobtitle.toLowerCase().includes(event.toLowerCase()))
        // alert(JSON.stringify(newData))
        // setsearchdata(newData)
        dispatch({
            type: "GET_JOBS_SEARCH",
            payload: newData
        })


    }





    return <Layout >


        {

members && members.status === "approved" ?

                <div style={{ paddingTop: "150px" }}>

                    <div className='mobile' style={{ width: "100%", display: "flex", justifyContent: "center", paddingBottom: "50px" }}>
                        <div class='mobile2' style={{ width: "50%", display: "flex", justifyContent: "space-around" }} >
                            <Button id="mobbutt1" onClick={() => setjob(1)} variant="contained" color="primary">Private Jobs</Button>
                            <button id="mobbutt2" onClick={() => setjob(2)} style={{ backgroundColor: "grey" }} className="btn btn-success mx-auto"  >Govt Jobs</button>
                            <Button id="mobbutt1" onClick={() => setjob(3)} variant="contained" color="secondary">Post a job</Button>
                        </div>
                    </div>

                    {jobs === 0 &&

                        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>

                            <img src={job} height="100%" width="100%" />
                        </div>
                    }

                    {jobs === 1 &&

                        <div style={{ width: "100%" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <input style={{ width: "50%" }} className="form-control" onChange={(e) => handlesearch(e.target.value)} type="text" name="search" placeholder="search any job" />
                            </div>

                            {/* {!searchdata.length ? */}



                            {!getalljobssearch.length ?

                                getalljobs.map((job) => {
                                    return (
                                        job.joboption === "private job" &&
                                        
                                        <Grid item xs={12} sm={5} style={{ marginTop: "30px" }} >
                                            <div class="pricing-box pricing-box-bg-image text-center" style={{ paddingTop: "30px" }}  >

                                                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.497)", width: "100%", paddingTop: "30px", marginTop: "30px" }}>
                                                    <h5 style={{ paddingTop: "20px", fontWeight: "bold" }}>{job.joboption}</h5>
                                                    <ul class="features-list">
                                                        <li><strong>title : </strong> {job.jobtitle}</li>
                                                        <li><strong>Detail : </strong> {job.jobdesc}</li>
                                                        <li><strong>post date : </strong> {job.date}</li>

                                                        {job.joboption === "government" &&

                                                            <button onClick={() => window.open(job.link, "_blank")} class="btn-primary">Apply Now</button>

                                                        }
                                                        {job.joboption === "private job" &&

                                                            <li><strong>contact : </strong> {job.jobcontact}</li>


                                                        }
                                                    </ul>
                                                </div>

                                            </div>
                                        </Grid>

                                    )

                                })



                                :

                                getalljobssearch.map((job) => {
                                    return (
                                        job.joboption === "private job" &&
                                        <Grid item xs={12} sm={4} style={{ paddingLeft: "50px", paddingRight: "50px", marginTop: "30px" }} >
                                            <div class="pricing-box pricing-box-bg-image text-center" style={{ height: "100%", paddingTop: "30px" }}  >

                                                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.497)", width: "100%", paddingTop: "30px", marginTop: "30px" }}>
                                                    <h5 style={{ paddingTop: "20px", fontWeight: "bold" }}>{job.joboption}</h5>
                                                    <ul class="features-list">
                                                        <li><strong>title : </strong> {job.jobtitle}</li>
                                                        <li><strong>Detail : </strong> {job.jobdesc}</li>
                                                        <li><strong>post date : </strong> {job.date}</li>

                                                        {job.joboption === "government" &&

                                                            <button onClick={() => window.open(job.link, "_blank")} class="btn-primary">Apply Now</button>

                                                        }
                                                        {job.joboption === "private job" &&

                                                            <li><strong>contact : </strong> {job.jobcontact}</li>


                                                        }
                                                    </ul>
                                                </div>

                                            </div>
                                        </Grid>

                                    )

                                })

                            }









                        </div>


                    }

                    {jobs === 2 &&



                        <div style={{ width: "100%" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <input style={{ width: "50%" }} className="form-control" onChange={(e) => handlesearch(e.target.value)} type="text" name="search" placeholder="search any job" />
                            </div>

                            {/* {!searchdata.length ? */}



                            {!getalljobssearch.length ?

                                getalljobs.map((job) => {
                                    return (
                                        job.joboption === "government" &&
                                        <Grid item xs={12} sm={5} style={{ marginTop: "30px" }} >
                                            <div class="pricing-box pricing-box-bg-image text-center" style={{ paddingTop: "30px" }}  >

                                                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.497)", width: "100%", paddingTop: "30px", marginTop: "30px" }}>
                                                    <h5 style={{ paddingTop: "20px", fontWeight: "bold" }}>{job.joboption}</h5>
                                                    <ul class="features-list">
                                                        <li><strong>title : </strong> {job.jobtitle}</li>
                                                        <li><strong>Detail : </strong> {job.jobdesc}</li>
                                                        <li><strong>post date : </strong> {job.date}</li>

                                                        {job.joboption === "government" &&

                                                            <button onClick={() => window.open(job.link, "_blank")} class="btn-primary">Apply Now</button>

                                                        }
                                                        {job.joboption === "private job" &&

                                                            <li><strong>contact : </strong> {job.jobcontact}</li>


                                                        }
                                                    </ul>
                                                </div>

                                            </div>
                                        </Grid>

                                    )

                                })



                                :

                                getalljobssearch.map((job) => {
                                    return (
                                        job.joboption === "government" &&
                                        <Grid item xs={12} sm={4} style={{ paddingLeft: "50px", paddingRight: "50px", marginTop: "30px" }} >
                                            <div class="pricing-box pricing-box-bg-image text-center" style={{ height: "100%", paddingTop: "30px" }}  >

                                                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.497)", width: "100%", paddingTop: "30px", marginTop: "30px" }}>
                                                    <h5 style={{ paddingTop: "20px", fontWeight: "bold" }}>{job.joboption}</h5>
                                                    <ul class="features-list">
                                                        <li><strong>title : </strong> {job.jobtitle}</li>
                                                        <li><strong>Detail : </strong> {job.jobdesc}</li>
                                                        <li><strong>post date : </strong> {job.date}</li>

                                                        {job.joboption === "government" &&

                                                            <button onClick={() => window.open(job.link, "_blank")} class="btn-primary">Apply Now</button>

                                                        }
                                                        {job.joboption === "private job" &&

                                                            <li><strong>contact : </strong> {job.jobcontact}</li>


                                                        }
                                                    </ul>
                                                </div>

                                            </div>
                                        </Grid>

                                    )

                                })

                            }









                        </div>
                    }


                    {jobs === 3 &&

                        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

                            <div class="form-wrapper-registration">

                                <select className="selectlist2" onChange={(event) => setjoboption(event.target.value)} >
                                    <option value={""} selected>Select job category</option>
                                    <option>private job</option>
                                    <option>government</option>
                                </select>
                            </div>

                            <div class="form-wrapper-registration">
                                <label for="">Job Title</label>
                                <input onChange={(e) => settitle(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                            </div>

                            {joboption === "government" &&

                                <div class="form-wrapper-registration">
                                    <label for="">Link</label>
                                    <input onChange={(e) => setlink(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                                </div>

                            }




                            <div style={{ paddingLeft: "70px" }} class="form-wrapper-registration">
                                <label for="">Job Description</label>
                                <textarea style={{ border: "2px solid grey", width: "90%" }} onChange={(event) => setdesc(event.target.value)} rows="4" cols="50">
                                    write job description...
                                </textarea>

                            </div>

                            {joboption === "private job" &&
                                <div class="form-wrapper-registration">
                                    <label for="">enter organization contact</label>
                                    <input placeholder='+92' onChange={(e) => setjobcontact(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                                </div>
                            }

                            <Button id="mobbutt1" onClick={postjob} variant="contained" color="secondary">Post</Button>

                        </div>


                    }




                </div>

                :


                <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center",marginTop:"100px",alignItems:"center" }}>
                 Your request is not approve yet or may be your account ban by admin due to unpaid membership monthly fees or any other reason.
                
                </div>
        }



    </Layout>
}


