




import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useStateValue } from '../../../components/StateProvider'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

import Paper from '@material-ui/core/Paper';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { BackServer } from "../../../Services"
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios'
import ClearIcon from '@material-ui/icons/Clear';
import arealist from "../../takearole-page/arealist"

const useStyles = makeStyles({
    table: {
        minWidth: 650,

    },

    tablecell: {
        color: "white",
    },
});





export default function MembersAdmin() {
    const classes = useStyles();
    const history = useHistory()

    const [{ members, getmembers, updatedmember, searchMembers }, dispatch] = useStateValue()
    const [userdataPopup, setuserdataPopup] = useState(false)
    const [url, seturl] = useState('')
    const [title, settitle] = useState('')
    const [rate, setrate] = useState('')
    const [desc, setdesc] = useState('')
    const [trackingid, settrackingid] = useState('')
    const [status, setstatus] = useState('')
    const [postid, setpostid] = useState('')
    const [deliveredDate, setdeliveredDate] = useState('')
    const [search, setsearch] = useState(false);
    const [membershipcategory, setmembershipcategory] = useState('');





    const [firstnamesearch, setfirstnamesearch] = useState('')
    const [areasearch, setareasearch] = useState('')
    const [businesssearch, setbusinesssearch] = useState('')
    const [bloodgroupsearch, setbloodgroupsearch] = useState('')
    const [cricketsearch, setcricketsearch] = useState("")


    const [selectsearch, setselectsearch] = useState(false)

    const [allmembersbuttonmobile, setallmembersbuttonmobile] = useState(false)

    const [searchnamemobile, setsearchnamemobile] = useState('')
    const [searchareamobile, setsearchareamobile] = useState('')
    const [searchbusiness, setsearchbusiness] = useState('')
    const [searchbloodgroup, setsearchbloodgroup] = useState('')
    const [searchcricket, setsearchcricket] = useState('')
    const [membershipcategorymobile, setmembershipcategorymobile] = useState('');


    const [writemessage, setwritemessage] = useState(false)
    const [writemessagetext, setwritemessagetext] = useState('')




    useEffect(() => {

        // document.body.scrollTop = 0
        // document.documentElement.scrollTop = 0

        var ADMIN = JSON.parse(localStorage.getItem('ADMIN'))
        if (!ADMIN) {
            history.push('/Admin')
        }


        var Members = JSON.parse(localStorage.getItem('members'))

        if (Members) {
            dispatch({
                type: 'SET_MEMBER',
                payload: Members,
            })
        }


        axios
            .get(`${BackServer}/data/getAllMembers`)
            .then((res) => {
                dispatch({
                    type: "GET_MEMBERS",
                    payload: res.data.allmembers
                })
            })




    }, [getmembers, updatedmember])



    const MemberApproved = () => {
        var updateMemberObject = {
            cricket: members.cricket,
            firstname: members.firstname,
            fathername: members.fathername,
            age: members.age,
            qualification: members.qualification,
            cnic: members.cnic,
            business: members.business,
            oaddress: members.oaddress,
            paddress: members.paddress,
            caddress: members.caddress,
            area: members.area,
            email: members.email,
            mobile: members.mobile,
            password: members.password,
            cricket: members.cricket,
            policy: members.policy,
            imageUrl: members.imageUrl,
            status: "approved",
            userId: members._id,

        }
        axios
            .post(`${BackServer}/data/updatemember`, updateMemberObject)
            .then((res) => {

                axios
                    .post(`${BackServer}/data/getUpdatedMembers`, members)
                    .then((res) => {

                        localStorage.setItem('members', JSON.stringify(res.data.updatedmember))
                        var Members = JSON.parse(localStorage.getItem('members'))

                        if (Members) {
                            dispatch({
                                type: 'SET_MEMBER',
                                payload: res.data.updatedmember,
                            })
                        }

                    })


            })


    }


    const filterMembers = () => {
        setselectsearch(true)

        const filterObj = {
            firstname: firstnamesearch.toLowerCase(),
            area: areasearch,
            business: businesssearch,
            cricket: cricketsearch,
            bloodgroup: bloodgroupsearch,
            membershipcategory: membershipcategory

        }
        firstnamesearch === "" && delete filterObj.firstname;
        areasearch === "" && delete filterObj.area;
        businesssearch === "" && delete filterObj.business;
        bloodgroupsearch === "" && delete filterObj.bloodgroup;
        cricketsearch === "" && delete filterObj.cricket;
        membershipcategory === '' && delete filterObj.membershipcategory

        axios
            .post(`${BackServer}/data/getSearchMembers`, filterObj)
            .then((res) => {
                setsearch(true)
                setallmembersbuttonmobile(true)


                dispatch({
                    type: "GET_SEARCH_MEMBERS",
                    payload: res.data.searchMembers
                })
            })
    }


    const cricketMethod = () => {
        setcricketsearch(true)
        alert("after press ok button you can see search icon press it and find all cricketers")
    }


    const selectoption = (value) => {
        setselectsearch(true)
        setsearchnamemobile(value)
        setsearchareamobile(value)
        setsearchbusiness(value)
        setsearchbloodgroup(value)
        setsearchcricket(value)
        setmembershipcategorymobile(value)
    }

    const messagebox = () => {

        setwritemessage(true)


    }

    const sendMessage = (searchmember) => {


        // alert(JSON.stringify(searchmember.length))

        if (searchmember) {
            var rr = searchmember
            var numbers = {
                contacts: [],
                writemessagetext: writemessagetext

            }
            for (var i = 0; i < rr.length; i++) {
                numbers.contacts.push(rr[i].mobile)
            }


            axios
                .post(`${BackServer}/data/sendmessage`, numbers)
                .then((res) => {
                    if (res) {
                        alert('successfully sms send')
                        setwritemessage(false)


                    }
                })

        }

        else {


            var allnumbers = {
                allemailcontacts: [],
                writemessagetext: writemessagetext
            }



            for (var i = 0; i < getmembers.length; i++) {
                if (getmembers[i].status === 'approved') {
                    allnumbers.allemailcontacts.push(getmembers[i].mobile)
                }
            }


            axios
                .post(`${BackServer}/data/sendmessage`, allnumbers)
                .then((res) => {
                    if (res) {
                        alert('successfully sms send')
                        setwritemessage(false)

                    }
                })

        }

    }




    return (
        <div style={{ marginBottom: "30px" }}>
            <div>
                {
                    writemessage === true &&

                    <div style={{ width: "100%", height: "100%", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} >


                        <textarea style={{ border: "2px solid red", width: "300px" }} onChange={(event) => setwritemessagetext(event.target.value)} rows="4" cols="50">
                            write any message...
                        </textarea>
                        <Button onClick={() => sendMessage(searchMembers)} variant="contained" color="secondary" style={{ marginTop: "30px", marginBottom: "30px" }}>send message </Button>
                        <Button onClick={() => setwritemessage(false)} variant="contained" color="primary" >Back</Button>

                    </div>
                }

            </div>

            {search === false ?
                <div style={{ display: "flex", justifyContent: "center", color: "red", fontSize: "25px", fontWeight: "bold", width: "100%", marginTop: "50px", flexDirection: "column", alignItems: "center" }}>
                    SEARCH BAR
                    <br />
                    <div style={{ fontSize: "9px", color: "#000000" }}>Note : (select what you want and press search icon)</div>
                </div>
                :

                <div className='clicktogetmembertext' style={{ display: "flex", justifyContent: "center", color: "#000000", fontSize: "25px", fontWeight: "bold", width: "100%", marginTop: "50px" }}>CLICK TO GET ALL MEMBERS</div>


            }

            <Grid className='searchbardesktop' item xs={12} sm={12} style={{ display: "flex", justifyContent: "center", paddingLeft: "50px", paddingRight: "50px", marginTop: "10px", marginBottom: "40px" }} >
                {search === false ?

                    <div className="selectMain" style={{ display: "flex", width: "55%", justifyContent: "center" }}>

                        <input className="selectlist" placeholder="Enter Member Name" onChange={(event) => setfirstnamesearch(event.target.value)} type="text " />


                        <select className="selectlist" onChange={(event) => setareasearch(event.target.value)} >
                            {/* <option value={""} selected>Select Area</option>
                            {getmembers && getmembers.map((item) => {
                                return (
                                    <option value={item.area}>{item.area}</option>
                                )
                            })} */}
                            <option value={null} selected>Select Area</option>
                            {arealist && arealist.map((item) => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                        </select>
                        <select className="selectlist" onChange={(event) => setbusinesssearch(event.target.value)} >
                            <option value={null} selected>Select Business</option>
                            {getmembers && getmembers.map((item) => {
                                return (
                                    <option value={item.business}>{item.business}</option>
                                )
                            })}
                        </select>

                        <select className="selectlist" onChange={(event) => setbloodgroupsearch(event.target.value)} >
                            {/* <option value={null} selected>Select BloodGroup</option>
                            {getmembers && getmembers.map((item) => {
                                return (
                                    <option value={item.bloodgroup}>{item.bloodgroup}</option>
                                )
                            })} */}

                            <option value={""} selected>Select Blood Group</option>
                            <option>A Positive</option>
                            <option>A Negative</option>
                            <option>A Unknown</option>
                            <option>B Positive</option>
                            <option>B Negative</option>
                            <option>B Unknown</option>
                            <option>AB Positive</option>
                            <option>AB Negative</option>
                            <option>AB Unknown</option>
                            <option>O Positive</option>
                            <option>O Negative</option>
                            <option>O Unknown</option>
                            <option>Unknown</option>
                        </select>

                        <select className="selectlist" onChange={(event) => setmembershipcategory(event.target.value)} >
                            <option value={""} selected>member category</option>
                            <option>General member</option>
                            <option>executive member</option>
                            <option>executive board member</option>
                        </select>

                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="selectlist" onClick={cricketMethod} >

                            Players

                        </div>
                        {firstnamesearch !== "" || areasearch !== "" || businesssearch !== "" || cricketsearch !== "" || bloodgroupsearch !== "" || membershipcategory !== "" ?

                            <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                            :

                            <div style={{ borderRadius: "50px", width: "90px", height: "30px", display: "flex", justifyContent: "center", margin: "5px", alignItems: "center" }}>
                            </div>
                        }

                    </div>

                    :

                    <Button onClick={() => {
                        setsearch(false);
                        setfirstnamesearch("");
                        setareasearch("")
                        setbusinesssearch("")
                        setcricketsearch("")
                        window.location.reload()

                    }} variant="contained" color="primary">ALL MEMBERS</Button>

                }

            </Grid>

            {/* mobile searchbar  */}

            {allmembersbuttonmobile === true &&
                <div className='allmemberbuttonmobile' style={{ display: "none", justifyContent: "center", width: "100%", marginTop: "20px" }}>
                    <Button onClick={() => {
                        setsearch(false);
                        setfirstnamesearch("");
                        setareasearch("")
                        setbusinesssearch("")
                        setcricketsearch("")
                        setallmembersbuttonmobile(false)
                        setselectsearch(false)
                        window.location.reload()


                    }} variant="contained" color="primary">ALL MEMBERS</Button>
                </div>


            }
            <Grid className='searchbarMobile' item xs={12} sm={12} style={{ display: "none", justifyContent: "center", paddingLeft: "50px", paddingRight: "50px", marginTop: "10px", marginBottom: "40px" }} >

                {selectsearch === false ?

                    <select className="selectlist" onChange={(e) => selectoption(e.target.value)} >
                        <option value='' selected >Select any option</option>
                        <option value='searchname'>search from member name</option>
                        <option value='searcharea'>search from member area</option>
                        <option value='searchbusiness'>search from member business</option>
                        <option value='searchbloodgroup'>search from member blood Group</option>
                        <option value='membershipcategorymobile'>member category</option>
                        <option value='searchcricket'>search Cricketers</option>
                    </select>

                    :

                    <div>
                        {searchnamemobile === "searchname" &&
                            <div>
                                <input className="selectlist" placeholder="Enter Member Name" onChange={(event) => setfirstnamesearch(event.target.value)} type="text " />
                                <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                            </div>
                        }

                        {searchareamobile === "searcharea" &&
                            <div>
                                <select className="selectlist" onChange={(event) => setareasearch(event.target.value)} >
                                    {/* <option value={""} selected>Select Area</option>
                                    {getmembers && getmembers.map((item) => {
                                        return (
                                            <option value={item.area}>{item.area}</option>
                                        )
                                    })} */}
                                    <option value={null} selected>Select Area</option>
                                    {arealist && arealist.map((item) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })}
                                </select>
                                <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                            </div>
                        }

                        {searchbusiness === "searchbusiness" &&
                            <div>
                                <select className="selectlist" onChange={(event) => setbusinesssearch(event.target.value)} >
                                    <option value={null} selected>Select Business</option>
                                    {getmembers && getmembers.map((item) => {
                                        return (
                                            <option value={item.business}>{item.business}</option>
                                        )
                                    })}
                                </select>
                                <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                            </div>
                        }





                        {searchbloodgroup === "searchbloodgroup" &&
                            <div>
                                <select className="selectlist" onChange={(event) => setbloodgroupsearch(event.target.value)} >
                                    {/* <option value={null} selected>Select BloodGroup</option>
                                    {getmembers && getmembers.map((item) => {
                                        return (
                                            <option value={item.bloodgroup}>{item.bloodgroup}</option>
                                        )
                                    })} */}
                                    <option value={""} selected>Select Blood Group</option>
                                    <option>A Positive</option>
                                    <option>A Negative</option>
                                    <option>A Unknown</option>
                                    <option>B Positive</option>
                                    <option>B Negative</option>
                                    <option>B Unknown</option>
                                    <option>AB Positive</option>
                                    <option>AB Negative</option>
                                    <option>AB Unknown</option>
                                    <option>O Positive</option>
                                    <option>O Negative</option>
                                    <option>O Unknown</option>
                                    <option>Unknown</option>
                                </select>
                                <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                            </div>
                        }
                        {membershipcategorymobile === 'membershipcategorymobile' &&
                            <div>
                                <select className="selectlist" onChange={(event) => setmembershipcategory(event.target.value)} >
                                    <option  >member category</option>
                                    <option>General member</option>
                                    <option>executive member</option>
                                    <option>executive board member</option>
                                </select>
                                <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                            </div>

                        }

                        {searchcricket === 'searchcricket' &&
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="selectlist" onClick={cricketMethod} >
                                    Search Cricketers
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                                </div>
                            </div>
                        }

                    </div>




                }




            </Grid>


            {/* mobile searchbar end */}





            <TableContainer style={{ paddingLeft: "10px", paddingRight: "50px" }} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ color: "white" }} >
                            <TableCell  >Name</TableCell>
                            <TableCell align="right">Fathername</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">business</TableCell>
                            <TableCell align="right">Mobile</TableCell>
                            <TableCell align="right">address</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {search === false ?
                            getmembers && getmembers.map((member, i) => (
                                member.status == "approved" &&
                                <TableRow style={{ backgroundColor: member.activateStatus === "deactivate" && "red", cursor: "pointer" }} onClick={() => history.push({ pathname: '/adminmemberdetail', state: member })} key={i} >
                                    <TableCell component="th" scope="row">
                                        {member.firstname}
                                    </TableCell>
                                    <TableCell align="right">{member.fathername}</TableCell>
                                    <TableCell align="right">{member.email}</TableCell>
                                    <TableCell align="right">{member.business}</TableCell>
                                    <TableCell align="right">{member.mobile}</TableCell>
                                    <TableCell align="right">{member.caddress}</TableCell>
                                </TableRow>
                            ))

                            :



                            searchMembers && searchMembers.map((member, i) => (

                                member.status == "approved" &&
                                <TableRow onClick={() => history.push({ pathname: '/adminmemberdetail', state: member })} key={i} style={{ cursor: "pointer" }}>
                                    <TableCell component="th" scope="row">
                                        {member.firstname}
                                    </TableCell>
                                    <TableCell align="right">{member.fathername}</TableCell>
                                    <TableCell align="right">{member.email}</TableCell>
                                    <TableCell align="right">{member.business}</TableCell>
                                    <TableCell align="right">{member.mobile}</TableCell>
                                    <TableCell align="right">{member.caddress}</TableCell>
                                    <TableCell align="right">{member.status}</TableCell>

                                </TableRow>

                            ))


                        }

                        {searchMembers && searchMembers && writemessage === true &&
                            <div>
                                <Button onClick={messagebox} variant="contained" color="secondary" style={{ marginTop: "30px", marginBottom: "30px", marginLeft: "30px" }}>write message </Button>
                            </div>
                        }

                        {getmembers && getmembers && writemessage === false &&

                            <div>
                                <Button onClick={messagebox} variant="contained" color="secondary" style={{ marginTop: "30px", fontSize: "8px", marginBottom: "30px", marginLeft: "30px" }}>write message </Button>
                            </div>

                        }

                        <Button variant="contained" color="primary" style={{ marginTop: "30px", marginBottom: "30px", marginLeft: "30px", fontSize: "8px", width: "100%" }}>
                            <CSVLink style={{ color: "white" }} data={getmembers}>Download all member data</CSVLink>
                        </Button>



                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
}



