import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Zubair from "../../assets/images/zubair.jpg"
import StarRatings from 'react-star-ratings';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../components/theme";
import { BackServer } from "../../Services"
import axios from 'axios'

import { useStateValue } from '../../components/StateProvider'
import { Link, useHistory, useLocation } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import './members.css'
import arealist from '../takearole-page/arealist'

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Members() {
    const classes = useStyles();
    const history = useHistory();

    const [{ getmembers, members, searchMembers }, dispatch] = useStateValue();
    const [search, setsearch] = useState(false);

    const [firstnamesearch, setfirstnamesearch] = useState('')
    const [areasearch, setareasearch] = useState('')
    const [businesssearch, setbusinesssearch] = useState('')
    const [bloodgroupsearch, setbloodgroupsearch] = useState('')

    const [cricketsearch, setcricketsearch] = useState("")


    const [selectsearch, setselectsearch] = useState(false)

    const [allmembersbuttonmobile, setallmembersbuttonmobile] = useState(false)

    const [searchnamemobile, setsearchnamemobile] = useState('')
    const [searchareamobile, setsearchareamobile] = useState('')
    const [searchbusiness, setsearchbusiness] = useState('')
    const [searchbloodgroup, setsearchbloodgroup] = useState('')
    const [searchcricket, setsearchcricket] = useState('')




    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        if (!members) {
            history.push('/')
        }
        if (members) {
            axios
                .get(`${BackServer}/data/getAllMembers`)
                .then((res) => {
                    dispatch({
                        type: "GET_MEMBERS",
                        payload: res.data.allmembers
                    })
                })
        }

    }, [])

    const filterMembers = () => {
        setselectsearch(true)

        const filterObj = {
            firstname: firstnamesearch.toLowerCase(),
            area: areasearch,
            business: businesssearch,
            cricket: cricketsearch,
            bloodgroup: bloodgroupsearch
        }
        firstnamesearch === "" && delete filterObj.firstname;
        areasearch === "" && delete filterObj.area;
        businesssearch === "" && delete filterObj.business;
        bloodgroupsearch === "" && delete filterObj.bloodgroup;
        cricketsearch === "" && delete filterObj.cricket;

        axios
            .post(`${BackServer}/data/getSearchMembers`, filterObj)
            .then((res) => {
                setsearch(true)
                setallmembersbuttonmobile(true)
                // setselectsearch(false)



                dispatch({
                    type: "GET_SEARCH_MEMBERS",
                    payload: res.data.searchMembers
                })
            })
    }


    const cricketMethod = () => {
        setcricketsearch(true)
        alert("after press ok button you can see search icon press it and find all cricketers")
    }

    const selectoption = (value) => {
        setselectsearch(true)
        setsearchnamemobile(value)
        setsearchareamobile(value)
        setsearchbusiness(value)
        setsearchbloodgroup(value)
        setsearchcricket(value)
    }

    return <Layout >

        {

            members && members.status === "approved" ?

                <div className={classes.root} style={{ backgroundColor: "#04090ab8", paddingTop: "100px", paddingBottom: "80px", }}>

                    <Grid container spacing={3}>
                        {search === false ?
                            <div style={{ display: "flex", justifyContent: "center", color: "red", fontSize: "25px", fontWeight: "bold", width: "100%", marginTop: "50px", flexDirection: "column", alignItems: "center" }}>
                                SEARCH BAR
                                <br />
                                <div style={{ fontSize: "9px", color: "white" }}>Note : (select what you want and press search icon)</div>
                            </div>
                            :

                            <div className='clicktogetmembertext' style={{ display: "flex", justifyContent: "center", color: "#ffffff", fontSize: "25px", fontWeight: "bold", width: "100%", marginTop: "50px" }}>CLICK TO GET ALL MEMBERS</div>


                        }
                        <Grid className='searchbardesktop' item xs={12} sm={12} style={{ display: "flex", justifyContent: "center", paddingLeft: "50px", paddingRight: "50px", marginTop: "10px", marginBottom: "40px" }} >
                            {search === false ?

                                <div className="selectMain" style={{ display: "flex", width: "55%", justifyContent: "center" }}>


                                    <input className="selectlist" placeholder="Enter Member Name" onChange={(event) => setfirstnamesearch(event.target.value)} type="text " />


                                    <select className="selectlist" onChange={(event) => setareasearch(event.target.value)} >
                                        {/* <option value={""} selected>Select Area</option>
                                        {getmembers && getmembers.map((item) => {
                                            return (
                                                <option value={item.area}>{item.area}</option>
                                            )
                                        })} */}
                                        <option value={null} selected>Select Area</option>
                                        {arealist && arealist.map((item) => {
                                            return (
                                                <option value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                    <select className="selectlist" onChange={(event) => setbusinesssearch(event.target.value)} >
                                        <option value={null} selected>Select Business</option>
                                        {getmembers && getmembers.map((item) => {
                                            return (
                                                <option value={item.business}>{item.business}</option>
                                            )
                                        })}
                                    </select>

                                    <select className="selectlist" onChange={(event) => setbloodgroupsearch(event.target.value)} >
                                        {/* <option value={null} selected>Select BloodGroup</option>
                                        {getmembers && getmembers.map((item) => {
                                            return (
                                                <option value={item.bloodgroup}>{item.bloodgroup}</option>
                                            )
                                        })} */}
                                        <option value={""} selected>Select Blood Group</option>
                                        <option>A Positive</option>
                                        <option>A Negative</option>
                                        <option>A Unknown</option>
                                        <option>B Positive</option>
                                        <option>B Negative</option>
                                        <option>B Unknown</option>
                                        <option>AB Positive</option>
                                        <option>AB Negative</option>
                                        <option>AB Unknown</option>
                                        <option>O Positive</option>
                                        <option>O Negative</option>
                                        <option>O Unknown</option>
                                        <option>Unknown</option>
                                    </select>

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="selectlist" onClick={cricketMethod} >

                                        Search Cricketers

                                    </div>
                                    {firstnamesearch !== "" || areasearch !== "" || businesssearch !== "" || cricketsearch !== "" || bloodgroupsearch !== "" ?

                                        <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                                        :

                                        <div style={{ borderRadius: "50px", width: "90px", height: "30px", display: "flex", justifyContent: "center", margin: "5px", alignItems: "center" }}>
                                        </div>
                                    }

                                </div>

                                :

                                <Button onClick={() => {
                                    setsearch(false);
                                    setfirstnamesearch("");
                                    setareasearch("")
                                    setbusinesssearch("")
                                    setcricketsearch("")
                                    window.location.reload()

                                }} variant="contained" color="primary">ALL MEMBERS</Button>


                            }

                        </Grid>
                        {/* mobile searchbar  */}

                        {allmembersbuttonmobile === true &&
                            <div className='allmemberbuttonmobile' style={{ display: "none", justifyContent: "center", width: "100%", marginTop: "20px" }}>
                                <Button onClick={() => {
                                    setsearch(false);
                                    setfirstnamesearch("");
                                    setareasearch("")
                                    setbusinesssearch("")
                                    setcricketsearch("")
                                    setallmembersbuttonmobile(false)
                                    setselectsearch(false)
                                    window.location.reload()


                                }} variant="contained" color="primary">ALL MEMBERS</Button>
                            </div>


                        }
                        <Grid className='searchbarMobile' item xs={12} sm={12} style={{ display: "none", justifyContent: "center", paddingLeft: "50px", paddingRight: "50px", marginTop: "10px", marginBottom: "40px" }} >

                            {selectsearch === false ?

                                <select className="selectlist" onChange={(e) => selectoption(e.target.value)} >
                                    <option value='' selected >Select any option</option>
                                    <option value='searchname'>search from member name</option>
                                    <option value='searcharea'>search from member area</option>
                                    <option value='searchbusiness'>search from member business</option>
                                    <option value='searchbloodgroup'>search from member blood Group</option>
                                    <option value='searchcricket'>search Cricketers</option>
                                </select>

                                :

                                <div>
                                    {searchnamemobile === "searchname" &&
                                        <div>
                                            <input className="selectlist" placeholder="Enter Member Name" onChange={(event) => setfirstnamesearch(event.target.value)} type="text " />
                                            <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                                        </div>
                                    }

                                    {searchareamobile === "searcharea" &&
                                        <div>
                                            <select className="selectlist" onChange={(event) => setareasearch(event.target.value)} >
                                                {/* <option value={""} selected>Select Area</option>
                                                {getmembers && getmembers.map((item) => {
                                                    return (
                                                        <option value={item.area}>{item.area}</option>
                                                    )
                                                })} */}

                                                <option value={null} selected>Select Area</option>
                                                {arealist && arealist.map((item) => {
                                                    return (
                                                        <option value={item}>{item}</option>
                                                    )
                                                })}
                                            </select>
                                            <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                                        </div>
                                    }

                                    {searchbusiness === "searchbusiness" &&
                                        <div>
                                            <select className="selectlist" onChange={(event) => setbusinesssearch(event.target.value)} >
                                                <option value={null} selected>Select Business</option>
                                                {getmembers && getmembers.map((item) => {
                                                    return (
                                                        <option value={item.business}>{item.business}</option>
                                                    )
                                                })}
                                            </select>
                                            <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                                        </div>
                                    }





                                    {searchbloodgroup === "searchbloodgroup" &&
                                        <div>
                                            <select className="selectlist" onChange={(event) => setbloodgroupsearch(event.target.value)} >
                                                {/* <option value={null} selected>Select BloodGroup</option>
                                                {getmembers && getmembers.map((item) => {
                                                    return (
                                                        <option value={item.bloodgroup}>{item.bloodgroup}</option>
                                                    )
                                                })} */}

                                                <option value={""} selected>Select Blood Group</option>
                                                <option>A Positive</option>
                                                <option>A Negative</option>
                                                <option>A Unknown</option>
                                                <option>B Positive</option>
                                                <option>B Negative</option>
                                                <option>B Unknown</option>
                                                <option>AB Positive</option>
                                                <option>AB Negative</option>
                                                <option>AB Unknown</option>
                                                <option>O Positive</option>
                                                <option>O Negative</option>
                                                <option>O Unknown</option>
                                                <option>Unknown</option>
                                            </select>
                                            <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                                        </div>
                                    }

                                    {searchcricket === 'searchcricket' &&
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="selectlist" onClick={cricketMethod} >
                                                Search Cricketers
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <SearchIcon style={{ fontSize: "28px", color: "red", marginLeft: "10px" }} onClick={filterMembers} />
                                            </div>
                                        </div>
                                    }





                                </div>




                            }




                        </Grid>


                        {/* mobile searchbar end */}

                        {/* star members  */}
                        {getmembers && getmembers.map((item) => {

                            return (
                                item.status == "approved" && item.starmember == "star" && search === false &&

                                <Grid item xs={12} sm={4} style={{ paddingLeft: "50px", paddingRight: "50px", borderBottom: "4px solid red", paddingBottom: "30px" }} >

                                    <div class="pricing-box pricing-box-bg-image text-center" style={{ height: "100%", paddingTop: "30px" }}  >
                                        <div  onClick={()=>alert(JSON.stringify(item.stardesc))} style={{display:"flex",width:"100%",justifyContent:"flex-start",cursor:"pointer"}}>

                                            <StarRatings
                                          
                                                starEmptyColor="red"
                                                starHoverColor="red"
                                                numberOfStars={1}
                                                name='rating'
                                                color="red"
                                            />
                                        </div>
                                        <div style={{ backgroundColor: "rgba(0, 0, 0, 0.497)", width: "100%", paddingTop: "30px", marginTop: "30px" }}>

                                            <h5 style={{ paddingTop: "20px", fontWeight: "bold" }}>{item.firstname}

                                            </h5>
                                            <div class="price" style={{ display: "flex", justifyContent: "center" }}><Avatar alt="Remy Sharp" src={item.imageUrl} style={{ height: "100px", width: "100px" }} /></div>
                                            <ul class="features-list">
                                                <li><strong>Mobile : </strong>03126850002</li>
                                                <li><strong>Address : </strong> {item.caddress}</li>
                                                <li><strong>Business : </strong> {item.business}</li>
                                                <li><strong>area : </strong> {item.area}</li>

                                                <li><strong>Sportman ? : </strong>{item.cricket === "true" ? "YES" : "NO"}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })}

                        {/* star members end  */}

                        {search === false ?

                            getmembers && getmembers.map((item) => {
                                return (
                                    item.status == "approved" && !item.starmember &&


                                    <Grid item xs={12} sm={4} style={{ paddingLeft: "50px", paddingRight: "50px" }} >
                                        <div class="pricing-box pricing-box-bg-image text-center" style={{ height: "100%", paddingTop: "30px" }}  >
                                            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.497)", width: "100%", paddingTop: "30px", marginTop: "30px" }}>

                                                <h5 style={{ paddingTop: "20px", fontWeight: "bold" }}>{item.firstname}</h5>
                                                <div class="price" style={{ display: "flex", justifyContent: "center" }}><Avatar alt="Remy Sharp" src={item.imageUrl} style={{ height: "100px", width: "100px" }} /></div>
                                                <ul class="features-list">
                                                    <li><strong>Mobile : </strong>03126850002</li>
                                                    <li><strong>Address : </strong> {item.caddress}</li>
                                                    <li><strong>Business : </strong> {item.business}</li>
                                                    <li><strong>area : </strong> {item.area}</li>

                                                    <li><strong>Sportman ? : </strong>{item.cricket === "true" ? "YES" : "NO"}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })



                            :

                            searchMembers && searchMembers.map((item) => {
                                return (
                                    item.status == "approved" &&

                                    <Grid item xs={12} sm={4} style={{ paddingLeft: "50px", paddingRight: "50px" }} >
                                        <div class="pricing-box pricing-box-bg-image text-center" style={{ height: "100%", paddingTop: "30px", }}  >
                                            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.497)", width: "100%", paddingTop: "30px", marginTop: "30px" }}>

                                                <h5 style={{ paddingTop: "20px", fontWeight: "bold" }}>{item.firstname}</h5>
                                                <div class="price" style={{ display: "flex", justifyContent: "center" }}><Avatar alt="Remy Sharp" src={item.imageUrl} style={{ height: "100px", width: "100px" }} /></div>
                                                <ul class="features-list">
                                                    <li><strong>Mobile : </strong>03126850002</li>
                                                    <li><strong>Address : </strong> {item.caddress}</li>
                                                    <li><strong>Business : </strong> {item.business}</li>
                                                    <li><strong>area : </strong> {item.area}</li>

                                                    <li><strong>Sportman ? : </strong>{item.cricket === "true" ? "YES" : "NO"}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })


                        }



                    </Grid>

                </div>

                :

                <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", marginTop: "100px", alignItems: "center" }}>
                    Your request is not approve yet or may be your account ban by admin due to unpaid membership monthly fees or any other reason.
                </div>

        }



    </Layout>
}


