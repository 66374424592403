import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../../components/theme";
import Donation from "../../../assets/images/donation.png"
import { useStateValue } from '../../../components/StateProvider'
import { Link, useHistory, useLocation } from "react-router-dom";
import { BackServer } from "../../../Services"
import axios from 'axios'
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "130px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Checkmemberdonationbyadmin() {
    const classes = useStyles();
    const history = useHistory();

    const [{ members, membersDonationadmin, membershipfeesadmin }, dispatch] = useStateValue();
    const [total, settotal] = useState('')

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        var ADMIN = JSON.parse(localStorage.getItem('ADMIN'))
        if (!ADMIN) {
            history.push('/Admin')


        }


        // alert(JSON.stringify(history.location.state._id))


        axios
            .post(`${BackServer}/data/getmemberdonationadmin/${history.location.state._id}`)
            .then((res) => {


                dispatch({
                    type: "GETMEMBERSDONATIONADMIN",
                    payload: res.data.MEMBERDONATIONADMIN
                })
            })



        axios
            .post(`${BackServer}/data/getmemberWithPaidMembershipStatusadmin/${history.location.state._id}`)
            .then((res) => {

                dispatch({
                    type: 'MEMBERSHIPFEESADMIN',
                    payload: res.data.getmemberWithPaidMembershipStatus
                })

            })


    }, [])


    const get_total = () => {
        var rr = membersDonationadmin
        var sum = 0
        for (var i = 0; i < rr.length; i++) {
            var ts = sum + + rr[i].amount

            sum = ts
        }

        settotal(sum)
    }

    const Donationverify = (Userid) => {


        var user = {
            userID: Userid
        }
        axios
            .post(`${BackServer}/data/updatememberdonation`, user)
            .then((res) => {
                if (res && res.data && res.data.success) {
                    window.location.reload();
                    // history.push('/memberrequests')
                }
            })
    }

    const membershipfeesverify = (Userid) => {


        var user = {
            userID: Userid
        }
        axios
            .post(`${BackServer}/data/verifypaidmembershipfeesstatus`, user)
            .then((res) => {


                if (res && res.data && res.data.success) {
                    window.location.reload();
                    // history.push('/memberrequests')
                }
            })
    }



    const registrationfeesverify = (Userid) => {


        var user = {
            userID: Userid
        }
        axios
            .post(`${BackServer}/data/verifypaidregistrationfeesstatus`, user)
            .then((res) => {


                if (res && res.data && res.data.success) {
                    window.location.reload();
                    // history.push('/memberrequests')
                }
            })
    }
    

    return (

        <div class="container" style={{ marginTop: "130px", marginBottom: "300px" }}>


            <TableContainer style={{ marginTop: "150px", marginBottom: "100px" }} >
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ color: "white" }} >
                            <TableCell className={classes.tablecell} >Date</TableCell>
                            <TableCell className={classes.tablecell} align="right">Amount</TableCell>
                            <TableCell className={classes.tablecell} align="right">transaction</TableCell>
                            <TableCell className={classes.tablecell} align="right">Purpose</TableCell>
                            <TableCell className={classes.tablecell} align="right">comment</TableCell>

                            <TableCell className={classes.tablecell} align="right">Action</TableCell>



                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {membershipfeesadmin && membershipfeesadmin.map((member, i) => (
                            <TableRow style={{ cursor: "pointer" }}>

                                <TableCell className={classes.tablecell} >{member.paidmembershipfeesdate}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.paidmembershipfees}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.paidmembershipfeestransactionid}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{'membership fees'}</TableCell>
                                <TableCell className={classes.tablecell} align="right">..</TableCell>




                                {member.paidmembershipfeesstatus === "verified" ?

                                    <TableCell className={classes.tablecell} style={{ color: "green", fontWeight: "bold" }} align="right">verified</TableCell>


                                    :
                                    <TableCell className={classes.tablecell} align="right">
                                        <div class="row">
                                            <div onClick={() => membershipfeesverify(history.location.state._id)} class="col-sm-12">
                                                <a style={{ color: "white" }} class="btn btn-info " >verify</a>
                                            </div>
                                        </div>
                                    </TableCell>

                                }
                            </TableRow>

                        ))}


                        {membershipfeesadmin && membershipfeesadmin.map((member, i) => (
                            <TableRow style={{ cursor: "pointer" }}>

                                <TableCell className={classes.tablecell} >{member.paidregistraionfeesdate}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.registrationfees}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.paidregistrationfeestransactionid}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{'registration fees'}</TableCell>
                                <TableCell className={classes.tablecell} align="right">..</TableCell>




                                {member.paidregistrationfeesstatus === "verified" ?

                                    <TableCell className={classes.tablecell} style={{ color: "green", fontWeight: "bold" }} align="right">verified</TableCell>


                                    :
                                    <TableCell className={classes.tablecell} align="right">
                                        <div class="row">
                                            <div onClick={() => registrationfeesverify(history.location.state._id)} class="col-sm-12">
                                                <a style={{ color: "white" }} class="btn btn-info " >verify</a>
                                            </div>
                                        </div>
                                    </TableCell>

                                }
                            </TableRow>

                        ))}

                        {membersDonationadmin && membersDonationadmin.map((item, i) => (


                            <TableRow key={i} style={{ cursor: "pointer" }}>
                                <TableCell className={classes.tablecell} component="th" scope="row">
                                    {item.date}
                                </TableCell>
                                <TableCell className={classes.tablecell} align="right">{item.amount}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{item.transactionid}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{item.purpose}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{item.comment}</TableCell>

                                {item.donationStatus && item.donationStatus !== "approved" ?
                                    <TableCell className={classes.tablecell} align="right">

                                        <div class="row">
                                            <div onClick={() => Donationverify(item._id)} class="col-sm-12">
                                                <a style={{ color: "white" }} class="btn btn-info " >verify</a>
                                            </div>
                                        </div>


                                    </TableCell>
                                    :

                                    <TableCell style={{ color: "green", fontWeight: "bold" }} className={classes.tablecell} align="right">verified</TableCell>


                                }

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "25px", paddingRight: "120px" }}>

                <Button variant="contained" color="primary" onClick={get_total}>
                    <TableCell className={classes.tablecell} align="right">{total}</TableCell>

                    GET TOTAL
                </Button>
            </div>
        </div>

    )
}


