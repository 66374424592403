


import React from "react";
import styled from "styled-components";
import { colors, Media } from "./theme";
import { Container, Col } from "react-bootstrap";
import Plot from "../assets/images/plot.jpg";
import { gilroySemibold, gilroyBold, sourceSansPro, gilroyMediumItalic, gilroyExtrabold } from "./theme/fonts";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Grid from '@material-ui/core/Grid'

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
});
export default function Homesection2(props) {
    const classes = useStyles();


    const urlParam = window.location.pathname;


    return (
        <div>
            <Title1>
                HELP US TO ACHIEVE OUR GOAL
            </Title1>
            <Title2>
                About Our Charity
            </Title2>

            <Wrapper>
                <Grid container spacing={3}>

                    <Grid item xs={0} sm={1}>


                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <Title3>
                            BUILDING SCHOOL,COLLEGES AND HOSPITALS FOR NEEDY PEOPLES.
                        </Title3>
                        <Para>
                        With the behalf of Kamboh Wefare Anjuman Karachi, Kamboh Welfare Trust Karachi is deeply involved for services
                        to mankind for rehabilitation of deprived and poverty stricken suffering fellow citizens of karachi.
                        Kamboh Welfare Trust Karachi is striving for the betterment in the field of basic health and educational
                        facilities.The Trust is providing medical services like Dispensary with free
                        medicines to the deserving sick people of the locality of Gulshan-e-Maymar and Ahsanabad karachi.
                        Humbly appeal to well to do and establishment citizens to kindly come forward and join
                        our hands. 
                        
                        Donate to generate funds for BUILDING SCHOOL,COLLEGES AND HOSPITALS for needy peoples to serve under priviledge poverty stricken and ill fated families who are desperately looking
                        at you for support.
                        </Para>

                        <Hidden style={{ display: "flex", marginTop: "15%" }}>
                             <CustomCol lg={4} xs={12} md={12}>
                            <ArrowForwardIcon style={{color:"grey"}} />
                           <span  style={{color:"#f78d15",fontWeight:"bold",paddingLeft:"10px"}}>$400000</span>  <br/>
                           <Zoom >
                           <span style={{paddingLeft:"30px",color:"#9d9d9d",fontSize:"13px",fontWeight:"bold"}}>RAISED BY YOUR HELP</span>
                           </Zoom>
                        </CustomCol>
                      

                        <CustomCol lg={4} xs={12} md={12}>
                            <ArrowForwardIcon style={{color:"grey"}} />
                            <span style={{color:"#f78d15",fontWeight:"bold",paddingLeft:"10px"}}>$1000000</span>  <br/>
                            <Zoom delay={1000}>
                            <span style={{paddingLeft:"30px",color:"#9d9d9d",fontSize:"13px",fontWeight:"bold"}}>IMMEDIATE NEED</span>
                            </Zoom>
                        </CustomCol>

                        <CustomCol lg={4} xs={12} md={12}>
                            <ArrowForwardIcon style={{color:"grey"}} />
                            <span style={{color:"#f78d15",fontWeight:"bold",paddingLeft:"10px"}}>$5000000</span>  <br/>
                            <Zoom delay={2000}>
                            <span style={{paddingLeft:"30px",color:"#9d9d9d",fontSize:"13px",fontWeight:"bold"}}>OUR INITIAL TARGET</span>
                            </Zoom>
                        </CustomCol>
                    </Hidden>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TopicImg>
                            <img src={Plot} alt="-" />
                        </TopicImg>
                    </Grid>

                    <Grid item xs={0} sm={1}>

                    </Grid>

                </Grid>

            </Wrapper>
        </div>

    );
}
const Wrapper = styled.div`
width:100%;
${Media("xlscreens")} {
  height: 100%;
}

`;

const Title1 = styled.div`
width:100%;
display:flex;
justify-content:center;
padding-top:0px;
letter-spacing: -1px;
color:#9d9d9d;
font-weight:bold;
margin-top:2%;

font-family: ${gilroyMediumItalic} !important ;

${Media("xlscreens")} {
  height: 4vw;
}
${Media("tablet")} {
margin-top:-20%;

}
`;

const Hidden = styled.div`


${Media("xlscreens")} {
}
${Media("tablet")} {
  display:none !important;
}
`;

const Title2 = styled.div`
width:100%;
display:flex;
justify-content:center;
font-family: ${gilroyExtrabold} !important ;
font-weight:bold;
line-height: -20px;
letter-spacing: -1.5px;
${Media("xlscreens")} {
  font-size: 4.166vw;
  margin: 0.2vw 0;
}
${Media("tablet")} {
  font-size: 38px;
  margin: 0px 0;
  br {
  }
}
`;

const Title3 = styled.div`
width:100%;
font-weight:bold;
letter-spacing: -1px;
color:#9d9d9d;
font-size:20px;
display:flex;
justify-content:center;
align-item:center;
color:#f78d15;
font-family: ${gilroyMediumItalic} !important ;
padding-top:30px;

${Media("xlscreens")} {
  height: 4vw;
}
${Media("tablet")} {
padding-top:0px;
text-align:center;
}
`;

const Para = styled.div`
width:100%;
letter-spacing: 1px;
color:#9d9d9d;
font-size:14px;
text-align:justify;
font-family: ${sourceSansPro} !important ;
padding-top:20px;
${Media("xlscreens")} {
  height: 4vw;
}
${Media("tablet")} {
    padding-left:25px;
    padding-right:25px;

}
`;

const TopicImg = styled.div`
  width: 100%;
  position: relative;
  margin-top:20%;
  padding-left:50px;
  z-index: 0;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 1;
  }
  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    z-index: 9;

  }

  ${Media("tablet")} {
    padding-left:25px;
    padding-right:25px;
}
`;

const CustomCol = styled(Col)`
margin-top:70px;
`;






