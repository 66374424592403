import { colors } from 'components/theme'
import { createGlobalStyle } from 'styled-components'
export default createGlobalStyle`
    body{
        margin:0px;
        height:100vh;
    }
    #main{
        overflow:hidden !important;

    }
`
