import React,{useEffect} from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import contactBg from "../../assets/images/contactBg.jpg";
import PhoneIcon from "@material-ui/icons/Phone";
import DirectionsIcon from "@material-ui/icons/Directions";
import { colors, Media } from "../../components/theme";
import Layout from 'components/layout'
import { Link, useHistory, useLocation } from 'react-router-dom'

import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
    gilroyExtrabold,

} from "../../components/theme/fonts";


function Contact() {
  const history = useHistory()

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0


    }, [])
    return <Layout>
          <Wrapper>
        <ContactIntro>
          <h1>Get in Touch</h1>
          <p>
            Want to get in touch? We'd love to hear from you. Here's how you can
            reach us.
          </p>
        </ContactIntro>
        <GetinTouch>
          <Container>
            <Row>
              <Col lg={6} md={6}>
                <ContactBox>
                  <PhoneIcon />
                  <h2>Talk to us.</h2>
                  <p>
                    Want Donation? Just pick-up your phone and call at our
                    given numbers.
                  </p>
                  <h3>+92 300 2633309</h3>
                  <h3>+92 333 2319266</h3>
                </ContactBox>
              </Col>
              <Col lg={6} md={6}>
                <ContactBox>
                  <DirectionsIcon />
                  <h2>Reach us.</h2>
                  <p>
                    Kamboh Welfare Anjuman Karachi Located at PLOT NO. C-228 SECTOR - 2 AHSANABAD MAIN SOUN MIYANI ROAD SCHEME NO. 33 KARACHI
                    <br />
                    09am - 09am (Sunday open).
                  </p>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://maps.app.goo.gl/phfX2ihifAqgmfUD7"
                  >
                    Get Direction
                  </a>
                </ContactBox>
              </Col>
            </Row>
          </Container>
        </GetinTouch>
      </Wrapper>

    </Layout>
}





const Wrapper = styled.div`
  min-height: 100vh;
  margin-top: 76px;
  ${Media("xlscreens")} {
    margin-top: 3.95vw;
  }
  ${Media('tablet')}{
    margin-top:60px;
  }
`;
const ContactIntro = styled.div`
  min-height: 50vh;
  background-image: url(${contactBg});
  background-size: cover;
  position: relative;
  z-index: 9;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 52px;
    font-family: ${gilroyBold};
  }
  p {
    font-size: 18px;
    font-family: ${gilroyMedium};
  }
  &:before {
    background: #00000070;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -9;
  }
  ${Media("xlscreens")} {
    h1 {
      font-size: 2.7vw;
    }
    p {
      font-size: 1vw;
    }
  }
  ${Media("tablet")} {
    text-align: center;
    padding: 0px 15px;
    min-height: 60vh;
    h1{
      font-size:40px;
    }
    p{
      font-size:18px;
    }
  }
`;
const GetinTouch = styled.div`
  margin-top: -100px;
  position: relative;
  z-index: 99;
  ${Media("xlscreens")} {
    margin-top: -5.2vw;
  }
`;
const ContactBox = styled.div`
  background-color: white;
  min-height: 300px;
  box-shadow: 0px 0px 10px #00000050;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 80px;
  text-align: center;
  h2 {
    font-size: 32px;
    font-family: ${gilroySemibold};
    margin: 10px 0;
  }
  p {
    font-size: 15px;
    margin: 15px 0;
    margin-bottom: 20px;
    font-family: ${gilroyBold};

  }
  h3 {
    font-family: ${gilroySemibold};
    color: #2b93c5;
    font-size: 20px;
  }
  svg {
    font-size: 60px;
  }
  a {
    font-size: 20px;
    font-family: ${gilroySemibold};
    color: #2b93c5;
  }
  ${Media("xlscreens")} {
    min-height: 20.83vw;
    padding: 0 4.16vw;
    h2 {
      font-size: 1.66vw;
      margin: 0.53vw 0;
    }
    p {
      font-size: 0.8vw;
      margin: 0.78vw 0;
      margin-bottom: 1.04vw;
    }
    h3 {
      font-size: 1.04vw;
    }
    svg {
      font-size: 3.125vw;
    }
    a {
      font-size: 1.04vw;
    }
  }
  ${Media('tablet')}{
    padding: 0px 20px;
    margin-bottom:20px;
    h2 {
      font-size: 30px;
      margin: 10px 0;
    }
    p {
      font-size: 10px;
      margin: 15px 0;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 18px;
    }
    svg {
      font-size: 36px;
    }
    a {
      font-size: 18px;
    }
  }
`;
export default Contact;
