export const initialState = {

    members: null,
    updatedmember:'',
    getmembers: [],
    searchMembers:null,
    membersDonation:[],
    membershipfeesadmin: [],
    membershipfees:[],
    membersDonationadmin:[],
    getalljobs:[],
    getalljobssearch:[]
}

// Selector
export const getBasketTotal = (basket) =>
    basket?.reduce((amount, item) => item.price + amount, 0)

const reducer = (state, action) => {

    switch (action.type) {

        case "SET_MEMBER":
            return {
                ...state,
                members: action.payload
            }


        case "GET_MEMBERS":
            return {
                ...state,
                getmembers: action.payload
            }

            case "GET_SEARCH_MEMBERS":
                return {
                    ...state,
                    searchMembers: action.payload
                }
              
                case "GETMEMBERSDONATION":
                    return {
                        ...state,
                        membersDonation: action.payload
                    }


                    case "GETMEMBERSDONATIONADMIN":
                    return {
                        ...state,
                        membersDonationadmin: action.payload
                    }
                    case "MEMBERSHIPFEESADMIN":
                        return {
                            ...state,
                            membershipfeesadmin: action.payload
                        }

                        case "MEMBERSHIPFEES":
                            return {
                                ...state,
                                membershipfees: action.payload
                            }

                    
                    case "GET_JOBS":
                        return {
                            ...state,
                            getalljobs: action.payload
                        }

                        case "GET_JOBS_SEARCH":
                        return {
                            ...state,
                            getalljobssearch: action.payload
                        }

                        

                  

        default:
            return state

    }

}

export default reducer
