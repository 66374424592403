

const arealist = [
    'out of city',
    '72500	  Karachi Airport',
    '75290	  Gulistan e Johar',
    '75760	  Baldia Town',
    '75150	  Board of Secondary Education',
    '75530	  Karachi Cantt',
    '7100	  City GPO',
    '75600	  Clifton',
    '75250	  COD',
    '75180	  Darul-uloom',
    '75500	  Defence Society',
    '75150	  Export Processing Zone',
    '75950	  FB area Postal Code',
    '75300	  Gulshan-e-Iqbal',
    '75650	  Habib Bank',
    '75520	  Hotel Metropole',
    '75510	  Jinnah Post Graduate Medical Center',
    '74200	  Karachi GPO',
    '75270	  Karachi University',
    '75620	  Keemari',
    '75190	  Korangi Creek',
    '74900	  Korangi GPO',
    '75160	  Landhi Colony',
    '75900	  Liaqatabad',
    '75660	  Liyari',
    '75070	  Karachi Malir Cantt',
    '75050	  Karachi Malir City',
    '75890	  Manghopir',
    '75640	  Manora',
    '75780	  MariPur-CE',
    '75750	  Maripur-FA',
    '75460	  Mehmoodabad',
    '75100	  Model Colony',
    '75040	  Murad Memon Goth',
    '75260	  National Cement Industry- DALMIA',
    '74700	  Nazimabad GPO',
    '75850	  New Karachi',
    '74800	  New Town GPO',
    '74600	  North Nazimabad GPO',
    '75800	  Orange Town',
    '75280	  PCSIR',
    '75100	  PECHS',
    '75760	  Pakistan Machine Tool Factory',
    '75790	  Pakistan Naval Armament Depot',
    '75800	  Pakistan Steel Mills',
    '75200	  Pakistan Steel Mills Town Ship',
    '75020	  Port Muhammad Bin Qasim',
    '75120	  Quaidabad',
    '75210	  Rafa-e-Aam Society',
    '75700	  SITE',
    '74100	  Saddar Karachi GPO',
    '75230	  Shah Faisal Colony',
    '75350	  Shahra-e-Faisal',
    '75730	  Sher Shah Colony',
    '75580	  Sindh Governor House',
    '74550	  Nishter Road',
]

export default arealist