


import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory, useLocation } from "react-router-dom";
import { colors, Media } from "./theme";
import { Container, Col } from "react-bootstrap";
import AppBar from "@material-ui/core/AppBar";
import LogoURL from "../assets/images/logo.jpg";
import { gilroySemibold, gilroyBold, sourceSansPro, gilroyMediumItalic } from "./theme/fonts";
import Ticker from "react-ticker"
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import EventIcon from "@material-ui/icons/Event";
import ContactsIcon from "@material-ui/icons/Contacts";
import PeopleIcon from "@material-ui/icons/People";
import HealingIcon from "@material-ui/icons/Healing";
import { useStateValue } from '../components/StateProvider'
import Avatar from '@material-ui/core/Avatar';
import ilyas from "../assets/images/ilyas.jpg";
import { useEffect } from "react";
import axios from 'axios'
import { BackServer } from "../Services"


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});
export default function SwipeableTemporaryDrawer(props) {


  const history = useHistory();


  const [{ members, getmembers }, dispatch] = useStateValue();


  const [updatedmemberlocal, setupdatedmemberlocal] = useState('')
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  useEffect(() => {


    var Members = JSON.parse(localStorage.getItem('members'))
    if (Members) {
      dispatch({
        type: 'SET_MEMBER',
        payload: Members,
      })
    }

    if (members) {

      axios
        .get(`${BackServer}/data/getAllMembers`)
        .then((res) => {
          const updatedMem = res.data.allmembers.find(mem => mem._id === Members._id);
          localStorage.setItem('members', JSON.stringify(updatedMem));
          dispatch({
            type: "GET_MEMBERS",
            payload: res.data.allmembers
          })
        })


      axios
        .get(`${BackServer}/data/getAlljobs`)
        .then((res) => {
          dispatch({
            type: "GET_JOBS",
            payload: res.data.alljobs
          })
        })
    }


    if (members) {

      axios
        .post(`${BackServer}/data/getMydonation/${members._id}`)
        .then((res) => {

          dispatch({
            type: "GETMEMBERSDONATION",
            payload: res.data.MEMBERDONATION
          })
        })
    }





  }, [])
  const [array, setarray] = React.useState([
    { news: "news1", notifications: "notifications1", alerts: "alerts1" },

  ]);
  const urlParam = window.location.pathname;
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <MobMenuWrapper>

        <Divider />
        {getmembers && getmembers.map((member) => {
          return (
            members && members._id === member._id && members && members.status === "approved" && member && member.activateStatus === "activate" ?
              <Link to="/myprofile">
                <div style={{ marginLeft: "30px", display: "flex", alignItems: "center" }}>
                  <Avatar alt="Remy Sharp" src={members && members.imageUrl} className={classes.large} />

                  <MobMenuItem >
                    My Profile
                  </MobMenuItem>

                </div>
              </Link>
              :
              ""
          )
        })}
        <Divider />

        <Link to="/">
          <MobMenuItem className={urlParam === "/" ? "active" : ""}>
            <HomeIcon />
            Home
          </MobMenuItem>
        </Link>
        <Divider />
        <Link to="/about">
          <MobMenuItem className={urlParam === "/about" ? "active" : ""}>
            <LocalHospitalIcon />
            About
          </MobMenuItem>
        </Link>
        <Divider />

        {getmembers && getmembers.map((member) => {
          return (
            members && members._id === member._id && members && members.status === "approved" && member && member.activateStatus === "activate" ?

              <Link to="/members">
                <MobMenuItem className={urlParam === "/members" ? "active" : ""}>
                  <PeopleIcon />
                  Members
                </MobMenuItem>

              </Link>

              :

              ""
          )
        })}

        {getmembers && getmembers.map((member) => {
          return (
            members && members._id === member._id && members && members.status === "approved" && member && member.activateStatus === "activate" ?

              <Link to="/jobs">
                <MobMenuItem className={urlParam === "/jobs" ? "active" : ""}>
                  <PeopleIcon />
                  Jobs
                </MobMenuItem>

              </Link>

              :

              ""
          )
        })}



        <Divider />
        <Link to="/gallery">
          <MobMenuItem className={urlParam === "/gallery" ? "active" : ""}>
            <PeopleIcon />
            Gallery
          </MobMenuItem>
        </Link>


        <Divider />
        <Link to="/contact">
          <MobMenuItem className={urlParam === "/contact" ? "active" : ""}>
            <ContactsIcon />
            Contact
          </MobMenuItem>
        </Link>


        <Link to="/membership">
          <MobMenuItem
            className={urlParam === "/forms" ? "active" : ""}
          >
            <HealingIcon />
            Take a Role
          </MobMenuItem>
        </Link>
        <Divider />
      </MobMenuWrapper>
    </div>
  );
  const Rules = () => {


    history.push('/membership')

  }


  return (
    <Wrapper>
      <AppBar
        fixed="top"
        className="appBar"
        style={{ backgroundColor: "#ffffff" }}
      >
        <Container>
          <NavbarWrapper>
            <Col lg={4}>
              <Logo onClick={() => history.push('/')} src={LogoURL} alt="kamboh-anjuman Logo" />
            </Col>
            <Col lg={8}>
              <NabarMenus>
                <MenuItem>
                  <Link to="/">Home</Link>
                </MenuItem>

                <MenuItem>
                  <Link to="/about">About</Link>
                </MenuItem>

                {/* {members && members.status === "approved" && (
                  <MenuItem>
                    <Link to="/members">Members</Link>
                  </MenuItem>
                )} */}

                {/* {getmembers && getmembers.map((member) => {
                  return (
                    members && members._id === member._id ? <MenuItem> <Link to={members && members.status === "approved" ? "/members" : '/'}>Members</Link>  </MenuItem> : ""
                  )
                })} */}

                {getmembers && getmembers.map((member) => {
                  return (
                    members && members._id === member._id && members && members.status === "approved" && member && member.activateStatus === "activate" ?
                      <MenuItem> <Link to="/members">Members</Link>  </MenuItem> : ""
                  )
                })}

                {getmembers && getmembers.map((member) => {
                  return (
                    members && members._id === member._id && members && members.status === "approved" && member && member.activateStatus === "activate" ?
                      <MenuItem> <Link to="/jobs">Jobs</Link>  </MenuItem> : ""
                  )
                })}




                <MenuItem>
                  <Link to="/gallery">Gallery</Link>
                </MenuItem>

                <MenuItem>
                  <Link to="/contact">Contact</Link>
                </MenuItem>
                {/* <BookAppoint onClick={Rules}>
                  <span>Take A Role</span>
                </BookAppoint> */}

                <MenuItem onClick={Rules}>
                  <Link to="/about">Role</Link>
                </MenuItem>


                {/* {members && members.status === "approved" && (
                  <div style={{ marginLeft: "30px" }}>
                    <Link to="/myprofile"><Avatar alt="Remy Sharp" src={members && members.imageUrl} className={classes.large} /></Link>
                    <ItemCounter>
                      My Profile
                    </ItemCounter>
                  </div>
                )} */}

                {getmembers && getmembers.map((member) => {
                  return (
                    members && members._id === member._id && members && members.status === "approved" && member && member.activateStatus === "activate" ?
                      <div style={{ marginLeft: "30px" }}>
                        <Link to="/myprofile"><Avatar alt="Remy Sharp" src={members && members.imageUrl} className={classes.large} /></Link>
                        {/* <ItemCounter >
                          <span style={{marginLeft:"50px"}}> My Profile </span>
                        </ItemCounter> */}
                      </div> : ""
                  )
                })}



              </NabarMenus>
              <Ticker mode="smooth">
                {({ index }) => (
                  array.map((items) => {
                    return (
                      <div style={{ display: "flex", color: "red", fontWeight: "bold" }}>
                        alerts news and notifications

                      </div>
                    )
                  })

                )}
              </Ticker>

            </Col>
          </NavbarWrapper>

          <FlashmessageDesktop style={{ display: "none" }}>
            {getmembers && getmembers.map((member) => {
              return (
                members && members._id === member._id && members && members.status !== "approved" ?
                  <div style={{ width: "100%", backgroundColor: "pink", color: "red", marginTop: "10px", fontWeight: "bold", display: "flex", justifyContent: "center", padding: "5px", marginBottom: "10px", borderRadius: "10px" }}>
                    Ap ki request abhi approved nahi ha is liye ap special routes access nai kr sakte like "Members","Jobs" and "Your Profile"
                  </div> : ""
              )
            })}

            {getmembers && getmembers.map((member) => {
              return (
                members && members._id === member._id && members && member && member.activateStatus === "deactivate" ?
                  <div style={{ width: "100%", backgroundColor: "pink", color: "red", marginTop: "10px", fontWeight: "bold", display: "flex", justifyContent: "center", padding: "5px", marginBottom: "10px", borderRadius: "10px" }}>
                    ap ka account monthly membership fees na pay krny ki waja ya ksi or waja sy deactivate kr dia gya ha.
                  </div> : ""
              )
            })}
          </FlashmessageDesktop>




        </Container>

        <MobileNavbar style={{ display: "none" }} >
          <Logo onClick={() => history.push('/')} src={LogoURL} alt="Al-Shifa Logo" />
          <FlashmessageMobile >
            {getmembers && getmembers.map((member) => {
              return (
                members && members._id === member._id && members && members.status !== "approved" ?
                  <div style={{ width: "100%", backgroundColor: "pink", color: "red", marginTop: "10px", fontWeight: "bold", display: "flex", justifyContent: "center", padding: "5px", marginBottom: "10px", borderRadius: "10px" }}>
                  Pending...
                  </div> : ""
              )
            })}

            {getmembers && getmembers.map((member) => {
              return (
                members && members._id === member._id && members && member && member.activateStatus === "deactivate" ?
                  <div style={{ width: "100%", backgroundColor: "pink", color: "red", marginTop: "10px", fontWeight: "bold", display: "flex", justifyContent: "center", padding: "5px", marginBottom: "10px", borderRadius: "10px" }}>
                  Account Deactivate
                  </div> : ""
              )
            })}
          </FlashmessageMobile>
          <Button onClick={toggleDrawer("right", true)}>
            <MenuIcon />
          </Button>
      
        </MobileNavbar>
     
        <React.Fragment>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
        </React.Fragment>
      </AppBar>
    </Wrapper>
  );
}



const FlashmessageDesktop = styled.div`
  ${Media("xlscreens")} {
  display:block !important;
  }
  ${Media("tablet")} {
   

  }
`;

const FlashmessageMobile = styled.div`
  ${Media("xlscreens")} {

  }
  ${Media("tablet")} {
   
  }
`;


const Wrapper = styled.div`
  .MuiDrawer-paper {
    width: 70%;
  }
`;
const Logo = styled.img`
  height: 85px !important;
  ${Media("xlscreens")} {
    height: 3.64vw;
  }
  ${Media("tablet")} {
    height: 60px !important;

  }
`;
const MobileNavbar = styled.div`


  ${Media("tablet")} {
    display: block !important;
    height: 65px  !important;
    background-color: white  !important;
    display: flex  !important;
    align-items: center  !important;
    justify-content: space-between  !important;
    button:focus {
      outline: none  !important;
    }
    img {
      margin-left: 20px  !important;
    }
  }

  ${Media("tabletlandscape")} {
    display: block !important;
    height: 95px  !important;
    background-color: white  !important;
    display: flex  !important;
    align-items: center  !important;
    justify-content: space-between  !important;
    button:focus {
      outline: none  !important;
    }
    img {
      margin-left: 20px  !important;
    }
  }
  ${Media("xlscreens")} {
  }
`;
const NavbarWrapper = styled.div`
  height: 90px !important;
  display: flex;
  align-items: center;
  ${Media("xlscreens")} {
    height: 4vw;
  }
  ${Media("tablet")} {
    display: none;
  }

  ${Media("tabletlandscape")} {
    display: none;
  }

  ${Media("desktopscreens")} {
    display: none;
  }


`;
const NabarMenus = styled.div`
  display: flex;
   justify-content: flex-end;
  align-items: center;
`;
const MenuItem = styled.div`
  font-size: 18px !important;
  font-family: ${gilroyMediumItalic} !important ;
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  cursor: pointer;
  a {
    text-decoration: none;
    color: #000000;
    height: inherit;
    display: flex;
    align-items: center;
  }
  &::after {
    position: absolute;
    content: "";
    height: 5px;
    width: 100%;
    background-color: red;
    bottom: 1px ;
    width: 0px;
    transition: all 0.3s ease;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
  ${Media("xlscreens")} {
    font-size: 0.9vw;
    height: 4vw;
    padding: 0 1.46vw;
    &::after {
      height: 0.25vw;
    }
  }
`;
const BookAppoint = styled.button`
  background: ${colors.secondaryColor};
  padding: 0px 15px;
  font-size: 9px !important;
  font-family: ${gilroySemibold};
  border: none;
  position: relative;
  z-index: 9;
  border: 3px solid red;
  margin-left: 5px;
  border-radius: 50px;
  height: 50px;
  span {
    text-decoration: none;
    color: white;
  }
  &:focus {
    outline: none;
  }
  &:after {
    position: absolute;
    content: "";
    transform: scale(0.5);
    visibility: hidden;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: -9;
    background-color: white;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
    border-radius: 50px;
  }
  &:hover {
    border-color: ${colors.secondaryColor};
    span {
      color: ${colors.secondaryColor};
    }
    &:after {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
  ${Media("xlscreens")} {
    font-size: 0.7vw;
    height: 2.66vw;
    padding: 0 0.78vw;
    margin-left: 0.26vw;
    border-radius: 2.66vw;
    &:after {
      border-radius: 2.66vw;
    }
  }
`;
const MobMenuWrapper = styled.div`
  a {
    color: ${colors.secondaryColor};
    text-decoration: none;
  }
`;
const MobMenuItem = styled.div`
  padding: 25px 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: ${gilroySemibold};
  position: relative;
  svg {
    margin-right: 15px;
  }
  &.active {
    box-shadow: 0px 0px 10px #00000020;
    &:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 7px;
      background-color: ${colors.secondaryColor};
      left: 0;
    }
    &:after {
      content:'';
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-left: 7px solid ${colors.secondaryColor};
      border-bottom: 7px solid transparent;
      left:7px;
      position:absolute;
    }
  }
`;


const ItemCounter = styled.div`
  position: absolute;
  top: 30px !important;
  left:100%;
  font-weight:bold;
  color: black;
  font-size: 15px;
  height: 14px;
  width: 25px !important;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${Media("xlscreens")} {
    top: 1.46vw;
    right: 0.43vw;
    font-size: 0.73vw;
    height: 1.02vw;
    width: 1.02vw;
    border-radius: 0.73vw;
  }
`;








