import React, { useEffect } from 'react'
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles'

import { colors, Media } from "../../components/theme";
import { Container, Col } from "react-bootstrap";
import { gilroySemibold, gilroyBold, sourceSansPro, gilroyMediumItalic, gilroyExtrabold } from "../../components/theme/fonts";
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Fade from 'react-reveal/Fade'
import IntroBg from "../../assets/images/banner1.jpg";
import LogoURL from "../../assets/images/logo.jpg";


import consti from "../../assets/images/consti.jpg";
import consti1 from "../../assets/images/consti1.jpg";
import consti3 from "../../assets/images/consti3.jpg";
import consti4 from "../../assets/images/consti4.jpg";
import consti5 from "../../assets/images/consti5.jpg";
import consti6 from "../../assets/images/consti6.jpg";
import consti7 from "../../assets/images/consti7.jpg";
import consti8 from "../../assets/images/consti8.jpg";
import consti9 from "../../assets/images/consti9.jpg";
import consti10 from "../../assets/images/consti10.jpg";
import consti11 from "../../assets/images/consti11.jpg";
import consti12 from "../../assets/images/consti12.jpg";
import consti13 from "../../assets/images/consti13.jpg";
import consti14 from "../../assets/images/consti14.jpg";



import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Layout from 'components/layout'

import './about.css'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        marginTop: '13%',
        marginBottom: "3%",
        backgroundColor: "#56574f",
        borderBottom: "1px solid grey",
        paddingBottom: "30px",
        paddingTop: "30px"
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}))

function About() {
    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0


    }, [])
    const classes = useStyles()
    return <Layout>
        <div>
            <div style={{ backgroundColor: "black",marginTop:"100px" }}>
                <div class="about-section" style={{ backgroundImage: `url(${IntroBg})`, backgroundRepeat: "no-repeat" }}>
                    <div class="inner-container">
                        <h1>About Us</h1>
                        <p id="MontserratRegular" class="text">
                            With the behalf of Kamboh Wefare Anjuman Karachi, Kamboh Welfare Trust Karachi is deeply involved for services
                            to mankind for rehabilitation of deprived and poverty stricken suffering fellow citizens of karachi.
                            Kamboh Welfare Trust Karachi is striving for the betterment in the field of basic health and educational
                            facilities. The Trust is providing medical services like Dispensary with free
                            medicines to the deserving sick people of the locality of Gulshan-e-Maymar and Ahsanabad karachi.
                            Humbly appeal to well to do and establishment citizens to kindly come forward and join
                            our hands.

                            Donate to generate funds for BUILDING SCHOOL,COLLEGES AND HOSPITALS for needy peoples to serve under priviledge poverty stricken and ill fated families who are desperately looking
                            at you for support.
                        </p>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={LogoURL} width="25%" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <Title1 >
            MEET WITH OUR
        </Title1>
        <Title2>
            FOUNDER LEADERS
        </Title2>
        <Para>
            <Innerpara>
                Following are the founders of Kamboh Welfare Anjuman Karachi. Who stabilised it on 23th March 2009.
                Under the administration of Kamboh Welfare Anjuman a Trust was founded with the name of Kamboh Welfare Trust Karachi.
            </Innerpara>
        </Para>

        <Wrapper>


            <Grid container spacing={3}>
                <Grid item  xs={0} sm={1}>


                </Grid>

                <Grid item xs={12} sm={10}>
                    <Fade bottom >
                        <RadiusMain>
                            <div>
                                <Radiusdiv>
                                    M ilyas Kamboh
                                </Radiusdiv>
                                <Heading>
                                    (Governer House)
                                </Heading>
                            </div>
                            <div>
                                <Radiusdiv>
                                    ilyas Kamboh
                                </Radiusdiv>
                                <Heading>
                                    (chief editor bolan)
                                </Heading>
                            </div>
                            <div>
                                <Radiusdiv>
                                    Ibrahim Kamboh
                                </Radiusdiv>
                                <Heading>
                                    (belongs to Mattli)
                                </Heading>
                            </div>

                            <div>
                                <Radiusdiv>
                                    Majeed Kamboh
                                </Radiusdiv>
                                <Heading>
                                    (From Allied Bank)
                                </Heading>
                            </div>
                        </RadiusMain>
                    </Fade>
                </Grid>



                <Grid item  xs={0} sm={1}>


                </Grid>

                <Grid item  xs={0} sm={1}>


                </Grid>

                <Grid item xs={12} sm={10}>
                    <Fade bottom >
                        <RadiusMain>
                            <div>
                                <Radiusdiv>
                                    M Saleem Kamboh
                                </Radiusdiv>
                                <Heading>
                                    (From Golarchi)
                                </Heading>
                            </div>
                            <div>
                                <Radiusdiv>
                                    Ali Akbar Kamboh
                                </Radiusdiv>
                                <Phd id="phd" style={{ marginLeft: "30px" }}>
                                    (ADVOCATE)
                                </Phd>
                            </div>
                            <div>
                                <Radiusdiv>
                                    M Shafee Kamboh
                                </Radiusdiv>
                                <Heading>
                                    (gulshan maymar)
                                </Heading>
                            </div>

                            <div>
                                <Radiusdiv>
                                    Akram Kamboh
                                </Radiusdiv>
                                <Phd id="phd" style={{ marginLeft: "30px" }}>
                                    (PHD)
                                </Phd>
                            </div>
                        </RadiusMain>
                    </Fade>
                </Grid>



                <Grid item  xs={0} sm={1}>


                </Grid>

            </Grid>
        </Wrapper>

        <Title1 >
            OUR
        </Title1>
        <Title2>
            CONSTITUTION
        </Title2>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti1} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti3} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti4} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti5} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti6} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti7} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti8} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti9} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti10} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti11} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti12} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti13} width="25%" />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <img src={consti14} width="25%" />
        </div>


    </Layout>
}


const Phd = styled.div`
font-size:13px;
font-weight:bold;
font-family: ${gilroyExtrabold} !important ;
margin-top:20px;

${Media("xlscreens")} {
}
${Media("tablet")} {
    margin-left:20px !important;
    margin-top:15px;
    font-size:8px;
    font-weight:bold;
font-family: ${gilroyExtrabold} !important ;
}
`;

const Title1 = styled.div`
width:100%;
display:flex;
justify-content:center;
padding-top:50px;
letter-spacing: -1px;
color:#9d9d9d;
font-weight:bold;
margin-top:6%;
font-family: ${gilroyMediumItalic} !important ;

${Media("xlscreens")} {
  height: 4vw;
}
${Media("tablet")} {
}
`;

const Title2 = styled.div`
width:100%;
display:flex;
justify-content:center;
font-family: ${gilroyExtrabold} !important ;
font-weight:bold;
line-height: -20px;
letter-spacing: -1.5px;
${Media("xlscreens")} {
  font-size: 4.166vw;
  margin: 0.2vw 0;
}
${Media("tablet")} {
  font-size: 38px;
  margin: 20px 0;
  br {
  }
}
`;

const Para = styled.div`
width:100%;
display:flex;
justify-content:center;
font-family: ${gilroyMediumItalic} !important ;
margin-top:10px;
${Media("xlscreens")} {

}
${Media("tablet")} {
}
`;

const Innerpara = styled.div`
width:40%;
display:flex;
justify-content:center;
font-family: ${gilroyMediumItalic} !important ;
color:#9d9d9d;
text-align:justify;
${Media("xlscreens")} {

}
${Media("tablet")} {
width:80%;
text-align:center;

}
`;

const Wrapper = styled.div`
width:100%;
display: flex;
${Media("xlscreens")} {
  height: 100%;
}
${Media("tablet")} {
}
`;

const RadiusMain = styled.div`
 display:flex;
 justify-content:space-evenly;
 align-items:center;
 width:100%;
 margin-top:70px;
${Media("xlscreens")} {

}
${Media("tablet")} {
   margin-top:50px;
 
 

}
`;

const Radiusdiv = styled.div`
 background-color:#f78d15;
 display:flex;
 justify-content:center;
 align-items:center;
 border-radius:50px;
 width:100px;
 height:100px;
 font-size:10px !important;
font-family: ${gilroyExtrabold} !important ;
font-size:12px;
color:#ffffff;
font-weight:bold;
border:4px solid red;
${Media("xlscreens")} {

}
${Media("tablet")} {
    width:70px;
 height:70px;
 font-size:7px !important;

}
`;

const Heading = styled.div`
font-size:13px;
font-weight:bold;
font-family: ${gilroyExtrabold} !important ;
margin-top:20px;
${Media("xlscreens")} {

}
${Media("tablet")} {
    margin-top:15px;
    padding-left:15px;
    font-size:8px;

}
`;
export default About
