import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../components/theme";
import Button from '@material-ui/core/Button';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useStateValue } from '../../components/StateProvider'
import axios from 'axios'
import { Link, useHistory, useLocation } from "react-router-dom";
import { BackServer } from "../../Services"

import regbgimage from "../../assets/images/bg-registration-form-2.jpg"
import './takearole.css'
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
    gilroyExtrabold,

} from "../../components/theme/fonts";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "130px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Forgot() {
    const classes = useStyles();
    const [processing, setProcessing] = useState(false)
    const [{ members }, dispatch] = useStateValue()
    const [disabled, setDisabled] = useState(true)

    const [newpassword, setnewpassword] = useState('')
    const [confirmpassword, setconfirmpassword] = useState('')
    const [mobile, setmobile] = useState('')



    const history = useHistory()

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        setmobile(history.location.state)

        if(!history.location.state){
            history.push('/')
    }

    }, [])


    const _handleDone = () => {

      
       if (newpassword === "") {
            alert("Enter New Password")
        }

        else if (newpassword.length < 8) {
            alert("Password should be atleast 8 characters long")
        }

        else if (confirmpassword === "") {
            alert("Enter confirm Password")
        }


        else if (newpassword !== confirmpassword) {
            alert("password doesn't match")
        }

        else {


            setProcessing(true)

            var forgotData = {
                mobile:mobile,
                password: newpassword,
            }

            axios
                .post(`${BackServer}/data/forgot`, forgotData)

                .then((res) => {

                    if (res.data.success !== true) {
                        alert(JSON.stringify(res.data.error))
                        setProcessing(false)

                    }

                    else{
                        alert("password successfully update")
                        setProcessing(false)
                        history.push('/login')
                    }
                   

                })

        }


    }



    return <Layout>
        <div className='mobile' style={{ marginTop: "130px", width: "100%", display: "flex", justifyContent: "center" }}>
            <div className='mobile2' style={{ width: "50%", display: "flex", justifyContent: "space-around" }} >
                <Button id="mobbutt1" onClick={() => history.push('/donate')} variant="contained" color="primary">Donate Now</Button>
                <button id="mobbutt2" onClick={() => history.push('/login')} style={{ backgroundColor: "grey" }} className="btn btn-success mx-auto"  >Member Login</button>
                <Button id="mobbutt1" onClick={() => history.push('/membership')} variant="contained" color="secondary">Become A Member</Button>
            </div>
        </div>
        <div class="wrapperRegistration" style={{ backgroundImage: `url(${regbgimage})` }}>
            <div class="innerRegistration">

                <div className="Formdiv" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <h3 id="ph1h2h3h4h5h6ul" style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold" }}>Forgot Password</h3>

                

                    <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }} class="form-wrapper-registration">
                        <label for="">Enter New Password</label>
                        <input style={{ width: "40%" }} onChange={(e) => setnewpassword(e.target.value)} id="inputtextareaselectbutton" type="password" class="form-control-registration" />
                    </div>

                    <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }} class="form-wrapper-registration">
                        <label for=""> Confirm Password</label>
                        <input style={{ width: "40%" }} onChange={(e) => setconfirmpassword(e.target.value)} id="inputtextareaselectbutton" type="password" class="form-control-registration" />
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Button disabled={processing && disabled} variant="contained" color="secondary" onClick={_handleDone} style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold", marginTop: "30px" }} className="registration-button">
                            {!processing ?
                                <span>
                                    Done
                                </span>
                                :
                                <span >
                                    <PropagateLoader color="red" width={100}
                                    />
                                </span>
                            }

                        </Button>


                    </div>

                </div>

            </div>
        </div>
    </Layout>
}


