import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Layout from 'components/layout'
import styled from 'styled-components'
import { BackServer } from "../../../Services"
import axios from 'axios'

import { useStateValue } from '../../../components/StateProvider'
import { Link, useHistory, useLocation } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Postjobsadmin() {
    const classes = useStyles();
    const history = useHistory();

    const [{ alljobs, members, }, dispatch] = useStateValue();
    const [jobs, setjob] = useState(0);
    const [joboption, setjoboption] = useState('');


    const [jobtitle, settitle] = useState('');
    const [jobdesc, setdesc] = useState('');
    const [link, setlink] = useState('');
    const [jobcontact, setjobcontact] = useState('');










    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0


        var ADMIN = JSON.parse(localStorage.getItem('ADMIN'))
        if (!ADMIN) {
            history.push('/Admin')
        }




    }, [])


    const postjob = () => {
        var jobData = {
            jobtitle: jobtitle,
            jobdesc: jobdesc,
            link: link,
            jobcontact: jobcontact,
            date: new Date().toLocaleDateString('de-DE'),
            joboption: joboption

        }
        if (jobtitle === "" || jobdesc === "") {
            alert("All fields are required")
        }

        else if (joboption === "") {
            alert("select job category")
        }

        else if (joboption === 'private job' && jobcontact === "") {
            alert("enter organization contact ")
        }

        else if (joboption === 'government' && link === "") {
            alert("enter govt job link")
        }

        else {

            var ADMIN = JSON.parse(localStorage.getItem('ADMIN'))

            if (ADMIN) {
                axios
                    .post(`${BackServer}/data/postjob`, jobData)
                    .then((res) => {
                        alert('successfully post')
                        window.location.reload();
                    })
            }
        }
    }





    return (
        <div style={{ paddingTop: "150px" }}>



            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

                <div class="form-wrapper-registration">

                    <select className="selectlist2" onChange={(event) => setjoboption(event.target.value)} >
                        <option value={""} selected>Select job category</option>
                        <option>private job</option>
                        <option>government</option>
                    </select>
                </div>

                <div class="form-wrapper-registration">
                    <label for="">Job Title</label>
                    <input onChange={(e) => settitle(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                </div>

                {joboption === "government" &&

                    <div class="form-wrapper-registration">
                        <label for="">Link</label>
                        <input onChange={(e) => setlink(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                    </div>

                }




                <div style={{ paddingLeft: "70px" }} class="form-wrapper-registration">
                    <label for="">Job Description</label>
                    <textarea style={{ border: "2px solid grey", width: "90%" }} onChange={(event) => setdesc(event.target.value)} rows="4" cols="50">
                        write job description...
                    </textarea>

                </div>

                {joboption === "private job" &&
                    <div class="form-wrapper-registration">
                        <label for="">enter organization contact</label>
                        <input placeholder='+92' onChange={(e) => setjobcontact(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                    </div>
                }

                <Button id="mobbutt1" onClick={postjob} variant="contained" color="secondary">Post</Button>

            </div>


        </div>


    )




}


