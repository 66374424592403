import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../components/theme";
import Button from '@material-ui/core/Button';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useStateValue } from '../../components/StateProvider'
import axios from 'axios'
import { Link, useHistory, useLocation } from "react-router-dom";
import { BackServer } from "../../Services"

import regbgimage from "../../assets/images/bg-registration-form-2.jpg"
import './takearole.css'
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
    gilroyExtrabold,

} from "../../components/theme/fonts";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "130px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Login() {
    const classes = useStyles();
    const [processing, setProcessing] = useState(false)
    const [{ members }, dispatch] = useStateValue()
    const [disabled, setDisabled] = useState(true)
    const [mobile, setmobile] = useState('')
    const [password, setpassword] = useState('')
    const history = useHistory()

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }, [])
    const _handleLogin = () => {
        if (mobile === "") {
            alert("Enter Mobile Number")
        }
        else if (password === "") {
            alert("Enter Password")
        }

        else {

            setProcessing(true)

            var loginData = {
                mobile: mobile,
                password: password,
            }

            axios
                .post(`${BackServer}/data/login`, loginData)

                .then((res) => {

                    if (res.data.success !== true) {
                        alert(JSON.stringify(res.data.error))
                        setProcessing(false)

                    }


                    else {
                        localStorage.setItem('members', JSON.stringify(res.data.members))
                        var Members = JSON.parse(localStorage.getItem('members'))
                        if (Members) {
                            dispatch({
                                type: 'SET_MEMBER',
                                payload: Members,
                            })
                        } else {
                            dispatch({
                                type: 'SET_MEMBER',
                                payload: null,
                            })
                        }
                        history.push('/')
                        setProcessing(false)
                    }
                })

        }


    }



    return <Layout>
        <div className='mobile' style={{ marginTop: "170px", width: "100%", display: "flex", justifyContent: "center" }}>
            <div className='mobile2' style={{ width: "50%", display: "flex", justifyContent: "space-around" }} >
                <Button id="mobbutt1" onClick={() => history.push('/donate')} variant="contained" color="primary">Donate Now</Button>
                <button id="mobbutt2" onClick={() => history.push('/login')} style={{ backgroundColor: "grey" }} className="btn btn-success mx-auto"  >Member Login</button>
                <Button id="mobbutt1" onClick={() => history.push('/membership')} variant="contained" color="secondary">Become A Member</Button>
            </div>
        </div>
        <div class="wrapperRegistration" style={{ backgroundImage: `url(${regbgimage})` }}>
            <div class="innerRegistration">

                <div className="Formdiv" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <h3 id="ph1h2h3h4h5h6ul" style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold" }}>Login Form</h3>

                    <div style={{width:"100%",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}} class="form-wrapper-registration">
                        <label for="">Mobile Number</label>
                        <input style={{width:"40%"}} onChange={(e) => setmobile(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                    </div>

                    <div style={{width:"100%",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}} class="form-wrapper-registration">
                        <label for="">Password</label>
                        <input style={{width:"40%"}} onChange={(e) => setpassword(e.target.value)} id="inputtextareaselectbutton" type="password" class="form-control-registration" />
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Button disabled={processing && disabled} variant="contained" color="secondary" onClick={_handleLogin} style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold", marginTop: "30px" }} className="registration-button">
                            {!processing ?
                                <span>
                                    Login Now
                                </span>
                                :
                                <span >
                                    <PropagateLoader color="red" width={100}
                                    />
                                </span>
                            }

                        </Button>

                       
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <label style={{ fontSize: "13px" }} for="">
                            Forgot password ? <Button variant="contained" color="primary"  onClick={() => history.push({ pathname: '/generatecode', state:  Math.floor(Math.random() * 100000) })} style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold", width: "20px", height: "20px", fontSize: "8px" }} className="login-button">Forgot</Button>
                        </label>
                    </div>
                </div>

            </div>
        </div>
    </Layout>
}


