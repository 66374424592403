import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../components/theme";
import Button from '@material-ui/core/Button';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useStateValue } from '../../components/StateProvider'
import axios from 'axios'
import { Link, useHistory, useLocation } from "react-router-dom";

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import CurrencyFormat from 'react-currency-format'
import { BackServer } from "../../Services"

import regbgimage from "../../assets/images/bg-registration-form-2.jpg"
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
    gilroyExtrabold,

} from "../../components/theme/fonts";

import "./donatenow.css"
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "130px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Donatenow() {
    const classes = useStyles();



    const [processing, setProcessing] = useState(false)
    const [{ members, getmembers, membershipfees, membersDonation }, dispatch] = useStateValue()
    const [disabled, setDisabled] = useState(true)
    const [mobile, setmobile] = useState('')
    const [password, setpassword] = useState('')
    const [succeeded, setSucceeded] = useState(false)
    const [amount, setamount] = useState('')

    const [transactionid, settransactionid] = useState('')
    const [purpose, setpurpose] = useState('')

    const [comment, setcomment] = useState('')

    const history = useHistory()



    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0


        var Members = JSON.parse(localStorage.getItem('members'))
        dispatch({
            type: 'SET_MEMBER',
            payload: Members,
        })





        if (members) {
            axios
                .post(`${BackServer}/data/getmemberWithPaidMembershipStatus/${members._id}`)
                .then((res) => {

                    dispatch({
                        type: 'MEMBERSHIPFEES',
                        payload: res.data.getmemberWithPaidMembershipStatusVerified
                    })
                })


        }
        if (!members && members === null) {
            window.onload = function () {
                window.location.replace('http://localhost:3000')
            }
        }

    }, [])


    const _handleDonation = () => {


        if (!members) {
            alert("plz become a member first")
            history.push({
                pathname: `/`,
            })
        }


        else if (purpose === "") {
            alert("select purpose of payment")
        }

        else if (amount === "") {
            alert("Enter Amount")
        }

        else if (purpose === "Monthly Membership Fees" && members.paidmembershipfees < 0 && amount < (members.paidmembershipfees * -1 + members.membershipfees)) {
            alert(`Aap ki pichly months ki ${members.paidmembershipfees * -1} fees due hai. ab aap ko total ${(members.paidmembershipfees * -1) + members.membershipfees} fees jama karwani ha. ${members.paidmembershipfees * -1} pichla or ${members.membershipfees} is mahiny ka.`)
        }

        else if (purpose === "Monthly Membership Fees" && amount < members.membershipfees) {
            alert(`ap ki membership fees ${members.membershipfees} rupay ha mehrabani farma kr RS : ${members.membershipfees}  jama krwayen. `)
        }




        else if (purpose === 'other' && comment === "") {
            alert("enter any comment")
        }

        else if (transactionid === "") {
            alert("Enter transaction id")
        }



        else if (purpose === "registration fees" && amount !== '500') {
            alert(`registration fees Rs:500 ha register hony k liye ap ko Rs:500 hi jama krwana pry ga. agr ap monthly membership fees advance ma baijna chahty hain to select list ma monthly membership fees select kren.`)
        }

        else if (purpose !== "registration fees" && members.registrationfees === 0) {
            alert(`pehly registration fees pay kren.`)
        }




        else {

            setProcessing(true)

            var donationData = {
                amount: amount,
                purpose: purpose,
                transactionid: transactionid,
                members: members,
                userId: members._id,
                donationStatus: false,
                comment: comment,
                date: new Date().toLocaleDateString('de-DE'),

            }



            axios
                .post(`${BackServer}/data/mydonation`, donationData)

                .then((res) => {

                    if (res.data.success !== true) {
                        alert(JSON.stringify(res.data.error))
                        setProcessing(false)
                    }

                    else {
                        alert("successfully donate")
                        // dispatch({
                        //     type: 'SET_MEMBER',
                        //     payload: null,
                        // })

                        history.push('/')
                        setProcessing(false)
                        setamount('')
                    }
                })

        }


    }



    return <Layout>


        <div className='mobile' style={{ marginTop: "170px", width: "100%", display: "flex", justifyContent: "center" }}>
            <div className='mobile2' style={{ width: "50%", display: "flex", justifyContent: "space-around" }} >
                <Button id="mobbutt1" onClick={() => history.push('/donate')} variant="contained" color="primary">Donate Now</Button>
                <button id="mobbutt2" onClick={() => history.push('/login')} style={{ backgroundColor: "grey" }} className="btn btn-success mx-auto"  >Member Login</button>
                <Button id="mobbutt1" onClick={() => history.push('/membership')} variant="contained" color="secondary">Become A Member</Button>
            </div>
        </div>

        {!members ?

            <div class="wrapperRegistration" style={{ backgroundImage: `url(${regbgimage})` }}>
                <div class="innerRegistration">

                    <div className="Formdiv" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <h3 id="ph1h2h3h4h5h6ul" style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold" }}>Donation Form</h3>
                        <h3 style={{ fontWeight: "bold", fontSize: "15px", color: "red" }}> Account No:02310060002218</h3>
                        <h1 style={{ fontWeight: "bold", fontSize: "10px", color: "red",width:"100%",display:"flex",justifyContent:"center" }}> Account Name:KAMBOH WELFARE TRUST KARACHI</h1>

                        <p style={{ fontWeight: "bold", fontSize: "13px", color: "red",width:"100%",display:"flex",justifyContent:"center" }}>Faisal Bank</p>
                        <div style={{ width: "100%", color: "red", paddingLeft: "10px", paddingRight: "10px" }}>

                            <p style={{ fontWeight: "bold", fontSize: "13px", color: "red",width:"100%",display:"flex",justifyContent:"center" }} >

                                آپ اپنے عطیات اوپر دیے گئے ہمارے اکاؤنٹ نمبر پر جیز کیش یا کسی بھی دوسری بینک اکاؤنٹ سے بیج سکتے ہیں۔

                                <br />
                                <br />
                                <br />


                                YOU CAN TRANSFER YOUR DONATION GIVEN ACCOUNT NUMBER THROUGH JAZZ CASH,EASY PAISA OR ANY BANK ACCOUNT

                            </p>
                        </div>


                    </div>

                </div>
            </div>

            :

            <div class="wrapperRegistration" style={{ backgroundImage: `url(${regbgimage})` }}>
                <div class="innerRegistration">

                    <div className="Formdiv" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <h3 id="ph1h2h3h4h5h6ul" style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold" }}>Donation Form</h3>
                        <h3 style={{ fontWeight: "bold", fontSize: "15px", color: "red" }}> Jazz Cash Account No</h3>
                        <h3 style={{ fontWeight: "bold", fontSize: "15px", color: "green",width:"100%",display:"flex",justifyContent:"center" }}> 0321 8212276</h3>

                        <p style={{ fontWeight: "bold", fontSize: "13px", color: "green",width:"100%",display:"flex",justifyContent:"center",borderBottom:"3px solid red",paddingBottom:"30px"  }}>SHAHID BASHIR</p>
                        <div style={{ width: "100%", color: "red", fontWeight: "bold",paddingLeft:"10px",paddingRight:"10px" }}>

                            <p style={{ fontWeight: "bold", fontSize: "10px", color: "red",width:"100%",display:"flex",justifyContent:"center",textAlign:"justify" }} >

                                ap apna donation ya membership fees ya phr registration fees hamary uper diye gye Jazz cash account
                                ma baij sakte hain. jama krda donation , membership fees ya registration fees ko apny record ma rakhne k liye ap 
                                ko neche diya gya form fill krna pary ga. ap k baijy gye fund ki tasdeeq hamara admin ap k account number sy kry ga.
                                is liye ap ko account number ki field my apna bank account number darj krna ha jis account sy ap ny payment baiji.
                                agr ap ny jazz cash ya easypaisa sy payment baiji ha to jis number sy ap ny payment baiji ha wo number account number ki field my darj kren.




                            </p>
                        </div>

                        <div class="form-wrapper-registration">
                            <label for="">select donation purpose</label>

                            <select className="selectlist2" onChange={(event) => setpurpose(event.target.value)} >
                                <option value={""} selected>Select purpose</option>



                                {getmembers && getmembers.map((member) => {
                                    return (
                                        members._id === member._id && member && member.paidregistrationfeesstatus === "verified" ?

                                            ""

                                            :
                                            members._id === member._id && <option>registration fees</option>

                                    )
                                })}





                                {getmembers && getmembers.map((member) => {
                                    return (
                                        members._id === member._id && member && member.paidmembershipfees >= member.membershipfees && member.paidmembershipfeesstatus === "verified" ?

                                            ""

                                            :
                                            members._id === member._id && <option>Monthly Membership Fees</option>

                                    )
                                })}




                                <option>other</option>



                            </select>
                        </div>

                        {purpose === "other" &&

                            <div style={{ width: "50%" }} class="form-wrapper-registration">
                                <label for="">Comment</label>
                                <input placeholder="Write any comment" onChange={(e) => setcomment(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                            </div>
                        }



                        <div style={{ width: "50%" }} class="form-wrapper-registration">
                            <label for="">AMOUNT </label>
                            <input placeholder={purpose === "Monthly Membership Fees" ? members.paidmembershipfees === 0 ? members.membershipfees : members.paidmembershipfees : purpose === "registration fees" ? '500' : 'Minimum donation 100'} onChange={(e) => setamount(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                        </div>



                        <div style={{ width: "50%" }} class="form-wrapper-registration">
                            <label for="">Account Number</label>
                            <input placeholder="02310060002218" onChange={(e) => settransactionid(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                        </div>



                        {/* payment method  */}

                        <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "20px" }}>

                            <div style={{ width: "50%" }}>
                                <form >
                                    <div className="payment__priceContainer" style={{ marginTop: "30px" }}>

                                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                            <Button disabled={processing && disabled} variant="contained" color="secondary" onClick={_handleDonation} style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold", marginTop: "30px" }} className="registration-button">
                                                {!processing ?
                                                    <span>
                                                        Donate
                                                    </span>
                                                    :
                                                    <span>


                                                        <PropagateLoader color="red" width={100}

                                                        />
                                                    </span>
                                                }

                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* payment method end  */}




                    </div>

                </div>
            </div>

        }



    </Layout>
}


