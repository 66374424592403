import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import itemData from './itemData';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../components/theme";
import './gallery.css'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "170px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Gallery() {
    const classes = useStyles();
    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    
    
    }, [])
    return <Layout>
        <div id="desktop" className={classes.root}>
            <ImageList rowHeight={380} className={classes.imageList} cols={3}>
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={item.cols || 1}>

                        <img src={item.img} alt={item.title} />

                    </ImageListItem>
                ))}
            </ImageList>
        </div>

        <div id="mobile" style={{ display: "none",marginBottom:"50px" }}>
            {itemData.map((item) => (
                <img src={item.img} alt={item.title} width="100%" style={{paddingTop:"20px"}} />
            ))}
        </div>


    </Layout>
}


