import React, { useState } from 'react'
import styled from 'styled-components'
import Layout from 'components/layout'
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from "react-bootstrap";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import IntroBg from "assets/images/banner1.jpg";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
    gilroyExtrabold,

} from "../components/theme/fonts";
import { colors, Media } from "../components/theme";


const Homesection1 = () => {
  const history = useHistory();

    const [scrolled, setscrolled] = useState(false)

    const gotoTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    return (
            <div style={{ overflow: "hidden" }}>
                <IntroSection>
                    <Container>
                        <IntroTagLine>WELCOME TO KWAK</IntroTagLine>
                        <IntroPara>
                            <br />
                            Our mission is to bring the community together,<br />
                            unite "KAMBOH'S" to help each other, to achieve<br />
                            common goals, and to create better world for our future generations.
                        </IntroPara>

                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                        <Fade top big>
                            <BookAppoint onClick={()=>history.push('/donate')} style={{ backgroundColor: "green" }}>Donate</BookAppoint>
                            <BookAppoint onClick={()=>history.push('/login')} style={{backgroundColor:"blue"}}>Member Login</BookAppoint>
                            <BookAppoint onClick={()=>history.push('/membership')}>Become A Member</BookAppoint>


                        </Fade>

                    </Container>
                </IntroSection>

               



                <GoToTop
                    style={{ display: scrolled ? "flex" : "none" }}
                    onClick={gotoTop}
                >
                    <ArrowUpwardIcon />
                </GoToTop>
            </div>

    )
}

const IntroSection = styled.div`
  min-height: 100vh;
  background-image: url(${IntroBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .container {
    position: relative;
    z-index: 9;
  }
  &:before {
    position: absolute;
    z-index: 9;
    content: "";
    background-color: #2b2b35cc !important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  ${Media("xlscreens")} {
    padding-top: 3.95vw;
  }
  ${Media("tablet")} {
    text-align: center;
    padding:0px 15px;
    padding-top:10px;
    padding-bottom:0px;
    background-size:90vh ;
    height: 100vh ;


    &:before {
      position: absolute;
      z-index: 9;
      content: "";
      background-color: #2b2b35cc !important;
      height: 81%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
`;
const ImportantTopic = styled.section`
  background-color: ${colors.secondaryBG};
  position: relative;
  .react-reveal {
    width: 100%;
  }
  ${Media('tablet')}{
    padding:50px 15px;
  }
`;
const TopicCard = styled(Col)`
  border: 1px solid ${colors.borderColor};
  margin-top: 50px;
  display: flex;
  background-color: ${colors.secondaryColor};
  padding: 0;
  width: 100%;
  position: relative;
  ${Media("xlscreens")} {
    margin-top: 2.66vw;
    height: 20.83vw;
  }
  ${Media('tablet')}{
    align-items:center;
  }
`;
const CustomCol = styled(Col)`
  height: 100%;
  padding: 0;
`;
const TopicImg = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.5;
  }
  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: ${colors.secondaryColor + "70"};
    z-index: 9;
  }
`;
const TopicDetails = styled.div`
  padding: 50px;
  ${Media("xlscreens")} {
    padding: 2.66vw;
  }
  ${Media('tablet')}{
    padding:20px;
  }
`;
const TopicTitle = styled.h1`
  color: white;
  font-size: 42px;
  font-family: ${gilroyBold};
  margin-bottom: 15px;
  ${Media("xlscreens")} {
    font-size: 2.18vw;
    margin-bottom: 0.78vw;
  }
  ${Media('tablet')}{
    font-size:26px;
  }
`;
const TopicPara = styled.p`
  color: white;
  font-size: 18px;
  font-family: ${gilroyMedium};
  ${Media("xlscreens")} {
    font-size: 0.93vw;
  }
  ${Media('tablet')}{
    font-size:14px;
  }
`;
const ReadMoreBtn = styled.button`
  background-color: white;
  border: none;
  margin-top: 20px;
  padding: 10px 40px;
  font-size: 16px;
  border-radius: 20px;
  font-family: ${gilroySemibold};
  &:focus {
    outline: none;
  }
  ${Media("xlscreens")} {
    margin-top: 1.04vw;
    padding: 0.53vw 2.08vw;
    font-size: 0.83vw;
    border-radius: 1.04vw;
  }
`;
const SectionHeading = styled.h1`
  font-size: 62px;
  font-family: ${gilroyBold};
  color: white;
  ${Media("xlscreens")} {
    font-size: 3.22vw;
  }
  ${Media('tablet')}{
    font-size:40px;
  }
`;
const ImportTopSubHead = styled.p`
  font-size: 18px;
  font-family: ${gilroySemibold};
  color: white;
  ${Media("xlscreens")} {
    font-size: 0.93vw;
  }
  ${Media('tablet')}{
    font-size: 16px;
  }
`;
const IntroTagLine = styled.div`
  letter-spacing: 1px;
  font-family: ${gilroyBold} !important;
  color: ${colors.whiteColor} !important;
  font-weight:bold;
  ${Media("xlscreens")} {
    font-size: 1.14vw;
  }
  ${Media("tablet")} {
    font-size: 16px;
  }
`;
const IntroTitle = styled.h1`
  font-family: ${gilroyExtrabold} !important;
  line-height: 1;
  letter-spacing: -1.5px;
  color: ${colors.whiteColor};

  ${Media("xlscreens")} {
    font-size: 4.166vw;
    margin: 1.56vw 0;
  }
  ${Media("tablet")} {
    font-size: 38px;
    margin: 20px 0;
    br {
      display: none;
    }
  }
`;
const IntroPara = styled.p`
  font-family: ${gilroyMedium} !important;
  color: ${colors.whiteColor};
  ${Media("xlscreens")} {
    font-size: 1.25vw;
  }
  ${Media("tablet")} {
    font-size: 16px;
    font-family: ${gilroySemibold};
    br {
      display: none;
    }
  }
`;
const BookAppoint = styled.button`
  background: ${colors.redColor};
  padding: 0px 15px;
  color: white;
  font-weight:bold;
  font-size: 14px;
  font-family: ${gilroySemibold};
  border: none;
  position: relative;
  z-index: 9;
  border: 3px solid ${colors.secondaryColor};
  margin-left: 5px;
  margin-top: 0px;
  border-radius: 50px;
  height: 50px;
  &:focus {
    outline: none;
  }
  &:after {
    position: absolute;
    content: "";
    transform: scale(0.5);
    visibility: hidden;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: -9;
    background-color: white;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
    border-radius: 50px;
  }
  &:hover {
    border-color: ${colors.secondaryColor};
    color: ${colors.secondaryColor};
    &:after {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
  ${Media("xlscreens")} {
    font-size: 0.9vw;
    height: 2.66vw;
    padding: 0 0.78vw;
    margin-left: 0.26vw;
    border-radius: 2.66vw;
    &:after {
      border-radius: 2.66vw;
    }
  }

  ${Media("tablet")} {
  }
`;
const GoToTop = styled.div`
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: ${colors.secondaryColor};
  color: white;
  cursor: pointer;
  border-radius: 10px;
  height: 50px;
  width: 50px;
  text-align: center;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 24px;
  }
  &:hover {
    background-color: #555;
  }
  ${Media("xlscreens")} {
    bottom: 1.04vw;
    right: 1.57vw;
    border-radius: 0.53vw;
    height: 2.66vw;
    width: 2.66vw;
    svg {
      font-size: 1.25vw;
    }
  }
`;
export default Homesection1
