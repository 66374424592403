import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import Layout from 'components/layout'
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from "react-bootstrap";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import IntroBg from "assets/images/banner1.jpg";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
    gilroyExtrabold,

} from "../../../components/theme/fonts";
import { colors, Media } from "../../../components/theme";


const Homesection1 = () => {
    const history = useHistory();

    const [scrolled, setscrolled] = useState(false)

   
    useEffect(() => {

        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        var ADMIN = JSON.parse(localStorage.getItem('ADMIN'))
        if (!ADMIN) {
            history.push('/Admin')
        }
    }, [])


    return (
        <div style={{ overflow: "hidden" }}>
            <div style={{backgroundColor:"black",height:"100%"}}>
            <div style={{fontSize:"30px",color:"white",fontWeight:"bold",position:"absolute",display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%"}}>Kamboh Welfare Anjuman Karachi Admin Panel</div>
                <div style={{opacity:"0.3",}}>
                    <img src={IntroBg} height="100%" width="100%" />
                </div>
            </div>
        </div>

    )
}



export default Homesection1
