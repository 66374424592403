
import march20212 from "../../assets/images/march20212.jpg";
import march20213 from "../../assets/images/march20213.jpg";
import march20214 from "../../assets/images/march20214.jpg";
import march20215 from "../../assets/images/march20215.jpg";

import march20191 from "../../assets/images/march20191.jpg";
import march20192 from "../../assets/images/march20192.jpg";

import march20171 from "../../assets/images/march20171.jpg";
import march20172 from "../../assets/images/march20172.jpg";
import march20173 from "../../assets/images/march20173.jpg";

import march20151 from "../../assets/images/march20151.jpg";
import march20152 from "../../assets/images/march20152.jpg";
import march20153 from "../../assets/images/march20153.jpg";
import march20154 from "../../assets/images/march20154.jpg";


const itemData = [
       {
         img: march20213,
         title: 'Image',
         author: 'author',
         cols: 1,
      },
      {
        img: march20212,
        title: 'Image',
        author: 'author',
        cols: 2,
     },
     {
        img: march20214,
        title: 'Image',
        author: 'author',
        cols: 2,
     },
     {
        img: march20215,
        title: 'Image',
        author: 'author',
        cols: 1,
     },
     

     {
        img: march20151,
        title: 'Image',
        author: 'author',
        cols: 1,
     },
     {
        img: march20152,
        title: 'Image',
        author: 'author',
        cols: 1,
     },
     {
        img: march20153,
        title: 'Image',
        author: 'author',
        cols: 1,
     },
     {
        img: march20154,
        title: 'Image',
        author: 'author',
        cols: 1,
     },

     {
        img: march20171,
        title: 'Image',
        author: 'author',
        cols: 1,
     },

     {
        img: march20172,
        title: 'Image',
        author: 'author',
        cols: 1,
     },

     {
        img: march20173,
        title: 'Image',
        author: 'author',
        cols: 1,
     },

     {
        img: march20191,
        title: 'Image',
        author: 'author',
        cols: 1,
     },
     {
        img: march20192,
        title: 'Image',
        author: 'author',
        cols: 1,
     },
  
     ]

     export default itemData