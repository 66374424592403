


import React, { useEffect } from "react";
import styled from "styled-components";
import { colors, Media } from "./theme";
import { Container, Col } from "react-bootstrap";
import { gilroySemibold, gilroyBold, sourceSansPro, gilroyMediumItalic, gilroyExtrabold } from "./theme/fonts";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import haneef from "../assets/images/haneef.jpg";
import ibrahim from "../assets/images/ibrahim.jpg";
import saleem from "../assets/images/saleem.jpg";
import ilyas from "../assets/images/ilyas.jpg";
import qaziaslam from "../assets/images/qaziaslam.jpg";
import zafar from "../assets/images/zafar.jpg";
import shareef from "../assets/images/shareef.jpg";
import habib from "../assets/images/habib.jpg";
import khalil from "../assets/images/khalil.jpg";
import aliakbar from "../assets/images/aliakbar.jpg";
import lateef from "../assets/images/lateef.jpg";
import ashraf from "../assets/images/ashraf.jpg";

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid'

import clsx from "clsx";
import { useState } from "react";



const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        border: "2px solid red"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },

    media: {
        height: 140,
    },
    large: {
        width: theme.spacing(16),
        height: theme.spacing(16),
    },
}));
export default function Card1(props) {
    const classes = useStyles();
    const [index, setindex] = useState(6)
    const [index2, setindex2] = useState(4)
    const [index3, setindex3] = useState(2)

    const [presidents, setpresidents] = useState([
        { name: "Lateef kamboh", src: lateef, d: "Former President", desc: "Lateef kamboh is a kamboh welfare anjuman karachi member.after the election of 2009 He was first president of Kamboh welfare anjuman karachi from march 2009 to 2011." },
        { name: "khalil Ahmed Kamboh", src: khalil, d: "Former President", desc: "khalil Ahmed Kamboh is a kamboh welfare anjuman karachi member.after the election of 2011 He was new president of Kamboh welfare anjuman karachi from march 2011 to 2013." },
        { name: "Qazi M aslam Kamboh (Late)", src: qaziaslam, d: "Former President", desc: "Qazi M aslam Kamboh Late was a kamboh welfare anjuman karachi member.after the election of 2013 He was new president (1st time) of Kamboh welfare anjuman karachi from march 2013 to 2015." },
        { name: "Habib Kamboh", src: habib, d: "Former President", desc: "Habib Kamboh is a kamboh welfare anjuman karachi member.after the election of 2015 He was new president of Kamboh welfare anjuman karachi from march 2015 to 2017." },
        { name: "Qazi M aslam Kamboh (Late)", src: qaziaslam, d: "Former President", desc: "Qazi M aslam Kamboh Late was a kamboh welfare anjuman karachi member.after the election of 2017 He was elected new president (2nd time) of Kamboh welfare anjuman karachi from march 2017 to 2019 but with the pleasure of ALLAH he passed away on 1 july 2021." },
        { name: "Zafar Iqbal Kamboh (Late)", src: zafar, d: "Former President", desc: "Zafar Iqbal Late was a kamboh welfare anjuman karachi member.after the death of former president QAZI ASLAM KAMBOH He was new president of Kamboh welfare anjuman karachi from 2020 to 2021 but with the pleasure of ALLAH he also passed away on 19 july 2021." },
        { name: "Shareef Kamboh", src: shareef, d: "Current President", desc: "Shareef Kamboh is a current president of kamboh welfare anjuman karachi from SEP 2021 to march 2023 " }
    ])

    const [gensec, setgensec] = useState([
        { name: "Haji Ibrahim Kamboh", src: ibrahim, d: "Former G-Secretary", desc: "Haji Ibrahim Kamboh was a former General Secretary of kamboh welfare anjuman karachi from march 2009 to march 2011 " },
        { name: "Qazi M aslam Kamboh (Late)", src: qaziaslam, d: "Former G-Secretary", desc: "Qazi M aslam Kamboh (Late) was a former General Secretary of kamboh welfare anjuman karachi from march 2011 to march 2013 " },
        { name: "Haji Ibrahim Kamboh", src: ibrahim, d: "Former G-Secretary", desc: "Haji Ibrahim Kamboh was a former General Secretary of kamboh welfare anjuman karachi from march 2013 to march 2015 " },
        { name: "Ali Akbar Kamboh", src: aliakbar, d: "Former G-Secretary", desc: "Ali Akbar Kamboh was a former General Secretary of kamboh welfare anjuman karachi from march 2015 to march 2017 " },
        { name: "Haji Ibrahim Kamboh", src: ibrahim, d: "Current G-Secretary", desc: "Haji Ibrahim Kamboh is a current General Secretary of kamboh welfare anjuman karachi from march 2017 to march 2023 " }
    ])

    const [chairman, setchairman] = useState([
        { name: "Saleem Kamboh", src: saleem, d: "Former Chairman", desc: "Saleem Kamboh was a former Chairman of kamboh welfare Trust karachi from march 2009 to march 2015" },
        { name: "Qazi M aslam Kamboh (Late)", src: qaziaslam, d: "Former Chairman", desc: "Qazi M aslam Kamboh (Late) was a former Chairman of kamboh welfare Trust karachi from march 2015 to march 2017 " },
        { name: "Saleem Kamboh", src: saleem, d: "Current Chairman", desc: "Saleem Kamboh is a current Chairman of kamboh welfare Trust karachi from march 2017 to march 2023 " }
    ])



    const goToNext = () => {
        if (index === 6) {
            setindex(6);
        }
        else {
            setindex(index + 1 % presidents.length);
        }
    };


    const goToPrev = () => {
        if (index !== 0) {
            setindex(index - 1 % presidents.length);
        }
    };


    const goToNext2 = () => {
        if (index2 === 4) {
            setindex2(4);
        }
        else {
            setindex2(index2 + 1 % gensec.length);
        }
    };


    const goToPrev2 = () => {
        if (index2 !== 0) {
            setindex2(index2 - 1 % gensec.length);
        }
    };

    const goToNext3 = () => {
        if (index3 === 2) {
            setindex3(2);
        }
        else {
            setindex3(index3 + 1 % chairman.length);
        }
    };


    const goToPrev3 = () => {
        if (index3 !== 0) {
            setindex3(index3 - 1 % chairman.length);
        }
    };


    return (
        <div>
            <Wrapper>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar alt="Remy Sharp" src={presidents[index].src} className={classes.large} />
                                </div>
                                <CardContent>
                                    <Typography style={{ fontWeight: "bold" }} gutterBottom variant="p" component="h6">
                                        {presidents[index].name}
                                    </Typography>
                                    <Typography style={{ height: "18vh" }} variant="body2" color="textSecondary" component="p">
                                        {presidents[index].desc}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <ArrowLeftIcon onClick={goToPrev} style={{ color: "grey", cursor: "pointer", fontSize: "30px" }} />
                                <ArrowRightIcon onClick={goToNext} style={{ color: "grey", cursor: "pointer", fontSize: "30px" }} />

                                <Button size="small" color="primary">
                                    {presidents[index].d}
                                </Button>
                            </CardActions>
                        </Card>

                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar alt="Remy Sharp" src={gensec[index2].src} className={classes.large} />
                                </div>
                                <CardContent>
                                    <Typography style={{ fontWeight: "bold" }} gutterBottom variant="p" component="h6">

                                    {gensec[index2].name}

                                    </Typography>
                                    <Typography style={{ height: "18vh" }} variant="body2" color="textSecondary" component="p">
                                    {gensec[index2].desc}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                            <ArrowLeftIcon onClick={goToPrev2} style={{ color: "grey", cursor: "pointer", fontSize: "30px" }} />
                                <ArrowRightIcon onClick={goToNext2} style={{ color: "grey", cursor: "pointer", fontSize: "30px" }} />
                                <Button size="small" color="primary">
                                {gensec[index2].d}
                                </Button>
                            </CardActions>
                        </Card>

                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar alt="Remy Sharp" src={chairman[index3].src} className={classes.large} />
                                </div>
                                <CardContent>
                                    <Typography style={{ fontWeight: "bold" }} gutterBottom variant="p" component="h6">

                                    {chairman[index3].name}

                                    </Typography>
                                    <Typography style={{ height: "18vh" }} variant="body2" color="textSecondary" component="p">
                                    {chairman[index3].desc}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                            <ArrowLeftIcon onClick={goToPrev3} style={{ color: "grey", cursor: "pointer", fontSize: "30px" }} />
                                <ArrowRightIcon onClick={goToNext3} style={{ color: "grey", cursor: "pointer", fontSize: "30px" }} />
                                <Button size="small" color="primary">
                                {chairman[index3].d}
                                </Button>
                            </CardActions>
                        </Card>

                    </Grid>

                  

                    <Grid item xs={12} sm={3}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar alt="Remy Sharp" src={ashraf} className={classes.large} />
                                </div>
                                <CardContent>
                                    <Typography style={{ fontWeight: "bold" }} gutterBottom variant="p" component="h6">
                                        Dr M Ashraf Kamboh
                                    </Typography>
                                    <Typography style={{ height: "18vh" }} variant="body2" color="textSecondary" component="p">
                                    Dr M Ashraf Kamboh is a current Secrety Chairman of kamboh welfare trust karachi from june 21 to june 23
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">
                                Current Secrety Chairman
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>

                </Grid>

            </Wrapper>
        </div>

    );
}


const Wrapper = styled.div`
width:100%;
padding:20px;
padding-top:40px;
${Media("xlscreens")} {
  height: 100%;
}
${Media("tablet")} {
}
`;

const Title1 = styled.div`
width:100%;
display:flex;
justify-content:center;
padding-top:50px;
letter-spacing: -1px;
color:#9d9d9d;
font-weight:bold;
margin-top:6%;
font-family: ${gilroyMediumItalic} !important ;

${Media("xlscreens")} {
  height: 4vw;
}
${Media("tablet")} {
}
`;

const Title2 = styled.div`
width:100%;
display:flex;
justify-content:center;
font-family: ${gilroyExtrabold} !important ;
font-weight:bold;
line-height: -20px;
letter-spacing: -1.5px;
${Media("xlscreens")} {
  font-size: 4.166vw;
  margin: 0.2vw 0;
}
${Media("tablet")} {
  font-size: 38px;
  margin: 20px 0;
  br {
  }
}
`;

const Radiusdiv = styled.div`
 background-color:#f78d15;
 display:flex;
 justify-content:center;
 align-items:center;
 border-radius:50px;
 width:100px;
 height:100px;
font-family: ${gilroyExtrabold} !important ;
font-size:12px;
color:#ffffff;
font-weight:bold;
border:4px solid red;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const RadiusMain = styled.div`
 display:flex;
 justify-content:space-evenly;
 align-items:center;
 width:100%;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const Heading = styled.div`
font-size:13px;
font-weight:bold;
font-family: ${gilroyExtrabold} !important ;
margin-top:20px;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const CustomCol = styled(Col)`

margin-top:70px;

`;









