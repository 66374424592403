import React, { useEffect, useState } from 'react'
import Routes from './routes'
import { useStateValue } from './components/StateProvider'
import axios from 'axios'
import { BackServer } from "./Services"


import './app.css'

function App() {

  const [{ members, getmembers, updatedmember, membershipfees }, dispatch] = useStateValue()


  useEffect(() => {
    // alert('Our Website Is Under Construction Now')

    var Members = JSON.parse(localStorage.getItem('members'))
    if (Members) {
      dispatch({
        type: 'SET_MEMBER',
        payload: Members,
      })
    }

    if (members) {
      axios
        .get(`${BackServer}/data/getAllMembers`)
        .then((res) => {
          dispatch({
            type: "GET_MEMBERS",
            payload: res.data.allmembers
          })
        })

      axios
        .get(`${BackServer}/data/getAlljobs`)
        .then((res) => {
          dispatch({
            type: "GET_JOBS",
            payload: res.data.alljobs
          })
        })

    }





  }, [updatedmember, membershipfees])

  return <Routes />
}

export default App


// export default function TodoApp() {

//   const [array, setarray] = useState([
//     {name:"zubair",cast:"kamboh"},
//     {name:"saif",cast:"jatt"}

//   ])
//   const [item, setitem] = useState('')
//   const [editindex, seteditindex] = useState('')


//   const addEvent = () => {
//     setarray([...array, 
//     {name:item,cast:"jatmal"}
//     ])
//   }


//   const deleteEvent = (i) => {


//                      setarray((old)=>{
//                        return old.filter((f,index)=>{
//                          return i !== index
//                        })

//                      })
//   }


//   const editEvent = (i) => {

//           setitem(array[i].name)

//           seteditindex(i)
//   }

//   const updateEvent = (i) => {
  
//   return array[editindex].cast = item
   

// }



//   return (
//     <div style={{ height: "100%", width: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
//       Todo App

//       <div>
//         <input value={item} onChange={(e) => setitem(e.target.value)} type='text' />
//         <button onClick={addEvent}>add</button>
//         <button onClick={updateEvent}>update</button>

//       </div>

//       <div>
//         {array.map((names, i) => {
//           return (
//             <div>
//               {names.name} 
//               {names.cast}
//               <button class='primary' onClick={() => deleteEvent(i)}>delete</button>
//               <button onClick={() => editEvent(i)}>edit</button>
//             </div>
//           )
//         })}
//       </div>
//     </div>
//   )
// }