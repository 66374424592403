


import React, { useEffect } from "react";
import styled from "styled-components";
import { colors, Media } from "./theme";
import { Container, Col } from "react-bootstrap";
import { gilroySemibold, gilroyBold, sourceSansPro, gilroyMediumItalic, gilroyExtrabold } from "./theme/fonts";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import haneef from "../assets/images/haneef.jpg";
import ibrahim from "../assets/images/ibrahim.jpg";
import saleem from "../assets/images/saleem.jpg";
import ilyas from "../assets/images/ilyas.jpg";
import qaziaslam from "../assets/images/qaziaslam.jpg";
import zafar from "../assets/images/zafar.jpg";
import mujahid from "../assets/images/mujahid.jpg";
import gaffor from "../assets/images/gaffor.jpg";

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid'

import clsx from "clsx";
import { useState } from "react";



const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        border: "2px solid red",
        marginTop: "10%",
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },

    media: {
        height: 140,
    },
    large: {
        width: theme.spacing(16),
        height: theme.spacing(16),
    },
}));
export default function Card2(props) {
    const classes = useStyles();
    const [index, setindex] = useState(2)
    const [presidents, setpresidents] = useState([

        { name: "Qazi M aslam Kamboh Marhoom", src: qaziaslam, d: "Former President", desc: "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica" },
        { name: "Zafar Iqbal Kamboh Marhoom", src: zafar, d: "Former President", desc: "Zafar Iqbal was a kamboh welfare anjuman karachi member.after the death of former president QAZI ASLAM KAMBOH He was new president of Kamboh welfare anjuman karachi from 2020 to 2021 but with the pleasure of ALLAH he also passed away on 19 july 2021." },
        { name: "Haneef Kamboh", src: haneef, d: "Current President", desc: "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica" }

    ])



    const goToNext = () => {
        if (index === 2) {
            setindex(2);
        }
        else {
            setindex(index + 1 % presidents.length);
        }
    };
    const goToPrev = () => {
        if (index !== 0) {
            setindex(index - 1 % presidents.length);

        }
    };


    return (
        <div>


            <Wrapper>
                <Title1 >
                    MEET WITH OUR
                </Title1>
                <Title2>
                    Supreme Council
                </Title2>
                <Grid container spacing={3}>


                    <Grid item xs={12} sm={3}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar alt="Remy Sharp" src={ilyas} className={classes.large} />
                                </div>
                                <CardContent>
                                    <Typography style={{ fontWeight: "bold" }} gutterBottom variant="p" component="h6">
                                        Moulana Ilyas Kamboh
                                    </Typography>
                                    <Typography style={{ height: "18vh" }} variant="body2" color="textSecondary" component="p">
                                    Moulana Ilyas Kamboh is a Supreme Council chairman of Kamboh Welfare Anjuman Karachi
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Supreme Council Chairman
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar alt="Remy Sharp" src={mujahid} className={classes.large} />
                                </div>
                                <CardContent>
                                    <Typography style={{ fontWeight: "bold" }} gutterBottom variant="p" component="h6">
                                        M Aslam Kamboh
                                    </Typography>
                                    <Typography style={{ height: "18vh" }} variant="body2" color="textSecondary" component="p">
                                        M Aslam Kamboh is a Supreme Council member of Kamboh Welfare Anjuman Karachi
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Supreme Council Member
                                </Button>
                            </CardActions>
                        </Card>

                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar alt="Remy Sharp" src={haneef} className={classes.large} />
                                </div>
                                <CardContent>
                                    <Typography style={{ fontWeight: "bold" }} gutterBottom variant="p" component="h6">
                                        M Haneef Kamboh
                                    </Typography>
                                    <Typography style={{ height: "18vh" }} variant="body2" color="textSecondary" component="p">
                                        M Haneef Kamboh is a Supreme Council member of Kamboh Welfare Anjuman Karachi
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Supreme Council Member
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar alt="Remy Sharp" src={gaffor} className={classes.large} />
                                </div>
                                <CardContent>
                                    <Typography style={{ fontWeight: "bold" }} gutterBottom variant="p" component="h6">
                                        Abdul Gaffor Kamboh
                                    </Typography>
                                    <Typography style={{ height: "18vh" }} variant="body2" color="textSecondary" component="p">
                                        Abdul Gaffor Kamboh is a Supreme Council Voice Chairman of Kamboh Welfare Anjuman Karachi
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Supreme Council Voice Chairman
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Wrapper>
        </div>

    );
}
const Wrapper = styled.div`
width:100%;
padding:20px;
${Media("xlscreens")} {
  height: 100%;
}
${Media("tablet")} {
}
`;

const Title1 = styled.div`
width:100%;
display:flex;
justify-content:center;
letter-spacing: -1px;
color:#9d9d9d;
font-weight:bold;
margin-top:3%;
font-family: ${gilroyMediumItalic} !important ;

${Media("xlscreens")} {
  height: 4vw;
}
${Media("tablet")} {
}
`;

const Title2 = styled.div`
width:100%;
display:flex;
justify-content:center;
font-family: ${gilroyExtrabold} !important ;
font-weight:bold;
line-height: -100px;
letter-spacing: -1.5px;
margin-top:-30%;
${Media("xlscreens")} {
  font-size: 4.166vw;
  margin: 0.2vw 0;
margin-top:-3.5%;

}
${Media("tablet")} {
  font-size: 38px;
  margin: 20px 0;
  br {
  }
}
`;

const Radiusdiv = styled.div`
 background-color:#f78d15;
 display:flex;
 justify-content:center;
 align-items:center;
 border-radius:50px;
 width:100px;
 height:100px;
font-family: ${gilroyExtrabold} !important ;
font-size:12px;
color:#ffffff;
font-weight:bold;
border:4px solid red;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const RadiusMain = styled.div`
 display:flex;
 justify-content:space-evenly;
 align-items:center;
 width:100%;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const Heading = styled.div`
font-size:13px;
font-weight:bold;
font-family: ${gilroyExtrabold} !important ;
margin-top:20px;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const CustomCol = styled(Col)`

margin-top:70px;

`;









