import React, { useEffect, useState } from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";

import arealist from "./arealist"

import { makeStyles } from '@material-ui/core/styles';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../components/theme";
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useStateValue } from '../../components/StateProvider'
import { auth, storage, database } from '../../database'
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import { BackServer } from "../../Services"

import axios from 'axios'


import regbgimage from "../../assets/images/bg-registration-form-2.jpg"
import './takearole.css'
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
    gilroyExtrabold,

} from "../../components/theme/fonts";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "130px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Takearole() {
    const classes = useStyles();
    const history = useHistory();
    const [{ members }, dispatch] = useStateValue()
    const [processing, setProcessing] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [firstname, setfirstname] = useState('')
    const [fathername, setfathername] = useState('')
    const [age, setage] = useState('')
    const [qualification, setqualification] = useState('')
    const [cnic, setcnic] = useState('')
    const [business, setbusiness] = useState('')
    const [oaddress, setoaddress] = useState('')
    const [caddress, setcaddress] = useState('')
    const [paddress, setpaddress] = useState('')
    const [area, setarea] = useState('')
    const [email, setemail] = useState('')
    const [mobile, setmobile] = useState('')
    const [password, setpassword] = useState('')
    const [bloodgroup, setbloodgroup] = useState('')

    const [cricket, setcricket] = useState(false)
    const [policy, setpolicy] = useState(false)
    const [file, setfile] = useState(null)
    const [progress, setprogress] = useState('');
    const [imageUrl, setimageUrl] = useState('');
    const [donateButton, setdonateButton] = useState(false)
    const [loginButton, setloginButton] = useState(false)
    const [becomeMemberButton, setbecomeMemberButton] = useState(false)
    const [city, setcity] = useState('');

    

    const [membershipcategory, setmembershipcategory] = useState('')

    const [membershipfees, setmembershipfees] = useState('000')


    useEffect(() => {


        if (membershipcategory === "General member") {
            setmembershipfees('100')
        }

        else if (membershipcategory === "executive member") {
            setmembershipfees('300')

        }

        else if (membershipcategory === "executive board member") {
            setmembershipfees('500')
        }

    }, [membershipcategory])





    const upload = () => {

        const uploadTask = storage.ref(`images/${file.name}`).put(file)
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                var uploadprogress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                )
                setprogress(uploadprogress)
            },
            (error) => {
                console.log(error)
            },

            () => {
                storage
                    .ref('images')
                    .child(file.name)
                    .getDownloadURL()
                    .then((url) => {
                        alert("Successfully upload")
                        setimageUrl(url)
                    })
            },
        )
    }









    const _handleRegistration = () => {


        if (firstname === "" || fathername === "" || age === "" || qualification === "" || cnic === "" || business === "" || oaddress === "" || paddress === "" || caddress === "" || area === "" || email === "" || mobile === "" || password === "" || cricket === "" || policy === "" || bloodgroup === "" || membershipcategory === "") {
            alert("All fields are required")
        }

        else if (area === "out of city" && city === "") {
            alert("enter your city name")
        }
        else if (firstname.search(/[a-zA-Z]/) == -1) {
            alert("Name should be Alphabetic")
        }
        else if (fathername.search(/[a-zA-Z]/) == -1) {
            alert("FatherName should be Alphabetic")
        }
        else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            alert("Invalid Email Entered")
        }
        else if (mobile.length !== 11) {
            alert("Invalid Contact Entered")
        }
        else if (cnic.length !== 13) {
            alert("Invalid cnic Entered")
        }
        else if (password.length < 8) {
            alert("Password should be atleast 8 characters long")
        }
        else if (policy !== true) {
            alert("plz accept membership policy")
        }

        else {
            setProcessing(true)

            var membershipdata = {
                cricket: cricket,
                firstname: firstname,
                fathername: fathername,
                age: age,
                qualification: qualification,
                cnic: cnic,
                business: business,
                oaddress: oaddress,
                paddress: paddress,
                caddress: caddress,
                area: area,
                city:city,
                email: email,
                mobile: mobile,
                password: password,
                cricket: cricket,
                policy: policy,
                imageUrl: imageUrl,
                status: false,
                activateStatus: 'activate',
                bloodgroup: bloodgroup,
                membershipcategory: membershipcategory,
                membershipfees: membershipfees,
                paidmembershipfees: 0,
                paidmembershipfeestransactionid: '',
                paidmembershipfeesdate: '',
                paidmembershipfeesstatus: false,
                registrationfees: 0

            }


            axios
                .post(`${BackServer}/data/signup`, membershipdata)

                .then((res) => {
                    if (res.data.success !== true) {
                        alert(JSON.stringify(res.data.error))
                        setProcessing(false)
                    }

                    else {
                        localStorage.setItem('members', JSON.stringify(res.data.membership))
                        var Members = JSON.parse(localStorage.getItem('members'))

                        if (Members) {
                            dispatch({
                                type: 'SET_MEMBER',
                                payload: Members,
                            })
                        }

                        history.push('/donate')
                        setProcessing(false)
                        alert(`آپ کی ممبر شپ درخواست ایڈمن کو بھیج دی گئی ہے۔ براہِ مہربانی
                         Donate Now 
                         میں جا کر 
                         500
                         رجسٹریشن فیس جمع کروائیں، آپ کی ممبر شپ درخواست قبول کر دی جائے گی۔
                        درخواست قبول ہونے کی صورت میں آپ کو تصدیقی میسج بھیج دیا جائے گا۔
                        میسج  موصول ہونے کے بعد دوبارہ لاگ ان کریں اور ممبر شپ کی سہولیات سے فائدہ حاصل کریں۔ شکریہ۔
                        نوٹ: ممبر شپ فارم پُر کرتے جو موبائل نمبر اور پاسورڈ درج کروایا تھا اسی نمبر اور پاسورڈ کو استعمال کریں۔`)
                    }
                })
        }


    }


    const Membershipcategory = (e) => {

        setmembershipcategory(e)




    }




    return <Layout>
        <div className='mobile' style={{ marginTop: "170px", width: "100%", display: "flex", justifyContent: "center", }}>
            <div class='mobile2' style={{ width: "50%", display: "flex", justifyContent: "space-around" }} >
                <Button id="mobbutt1" onClick={() => history.push('/donate')} variant="contained" color="primary">Donate Now</Button>
                <button id="mobbutt2" onClick={() => history.push('/login')} style={{ backgroundColor: "grey" }} className="btn btn-success mx-auto"  >Member Login</button>
                <Button id="mobbutt1" onClick={() => history.push('/membership')} variant="contained" color="secondary">Become A Member</Button>
            </div>
        </div>

        <div class="wrapperRegistration" style={{ backgroundImage: `url(${regbgimage})` }}>

            <div class="innerRegistration">
                <div className="Formdiv">

                    <h3 id="ph1h2h3h4h5h6ul" style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold" }}>Registration Form</h3>

                    <div style={{ display: "flex", justifyContent: "center", height: "120px" }}>
                        <Avatar alt="Remy Sharp" src={imageUrl ? imageUrl : ""} style={{ height: "90px", width: "90px" }} />
                    </div>




                    <div class="form-group-registration">
                        <div class="form-wrapper-registration">
                            <input id="inputtextareaselectbutton" onChange={(e) => setfile(e.target.files[0])} type="file" />
                        </div>
                        <div class="form-wrapper-registration">

                            <button
                                onClick={upload}
                                className="btn btn-success mx-auto"
                            >
                                upload image
                            </button>
                            <div style={{ paddingTop: "20px" }}>
                                <LinearProgress variant="determinate" value={progress} color="secondary" />
                            </div>
                        </div>
                    </div>

                    <div class="form-group-registration">
                        <div class="form-wrapper-registration">
                            <label for="">First Name</label>
                            <input onChange={(e) => setfirstname(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                        </div>
                        <div class="form-wrapper-registration">
                            <label for="">Father Name</label>
                            <input onChange={(e) => setfathername(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                        </div>
                    </div>


                    <div class="form-group-registration">
                        <div class="form-wrapper-registration">
                            <label for="">Age</label>
                            <input onChange={(e) => setage(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                        </div>
                        <div class="form-wrapper-registration">
                            <label for="">Qualification</label>
                            <input onChange={(e) => setqualification(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                        </div>
                    </div>

                    <div class="form-group-registration">
                        <div class="form-wrapper-registration">
                            <label for="">CNIC</label>
                            <input onChange={(e) => setcnic(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                        </div>
                        <div class="form-wrapper-registration">
                            <label for="">Business?</label>
                            <input onChange={(e) => setbusiness(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                        </div>
                    </div>

                    <div class="form-wrapper-registration">
                        <label for="">office/shop address</label>
                        <input onChange={(e) => setoaddress(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                    </div>

                    <div class="form-wrapper-registration">
                        <label for="">Permanent address</label>
                        <input onChange={(e) => setpaddress(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                    </div>
                    <div class="form-wrapper-registration">
                        <label for="">current address</label>
                        <input onChange={(e) => setcaddress(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                    </div>


                    <div class="form-wrapper-registration">
                        <label for="">Email</label>
                        <input onChange={(e) => setemail(e.target.value)} id="inputtextareaselectbutton" type="email" class="form-control-registration" />
                    </div>

                    <div class="form-group-registration">
                        <div class="form-wrapper-registration">
                            <label for="">Mobile Number</label>
                            <input onChange={(e) => setmobile(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                        </div>
                        <div class="form-wrapper-registration">
                            <label for="">Password</label>
                            <input onChange={(e) => setpassword(e.target.value)} id="inputtextareaselectbutton" type="password" class="form-control-registration" />
                        </div>
                    </div>

                    <div class="form-group-registration">
                        <div class="form-wrapper-registration">
                            <label for="">Blood Group</label>

                            <select className="selectlist2" onChange={(event) => setbloodgroup(event.target.value)} >
                                <option value={""} selected>Select Blood Group</option>
                                <option>A Positive</option>
                                <option>A Negative</option>
                                <option>A Unknown</option>
                                <option>B Positive</option>
                                <option>B Negative</option>
                                <option>B Unknown</option>
                                <option>AB Positive</option>
                                <option>AB Negative</option>
                                <option>AB Unknown</option>
                                <option>O Positive</option>
                                <option>O Negative</option>
                                <option>O Unknown</option>
                                <option>Unknown</option>


                            </select>
                        </div>


                    </div>


                    <div class="form-group-registration">

                        <div class="form-wrapper-registration">
                            <label for="">Member Category</label>
                            <select className="selectlist2" onChange={(event) => Membershipcategory(event.target.value)} >
                                <option value={""} selected>member category</option>
                                <option>General member</option>
                                <option>executive member</option>
                                <option>executive board member</option>
                            </select>
                        </div>

                        <div style={{ paddingLeft: "25px" }} class="form-wrapper-registration">
                            <div>Monthly membership fees</div>
                            <div style={{ color: "red", fontWeight: "bold" }}>{membershipfees}</div>

                            <div>Registration fees</div>
                            <div style={{ color: "red", fontWeight: "bold" }}>500</div>

                        </div>

                    </div>

                    <div class="form-wrapper-registration">
                        <label for="">Your Area</label>
                        {/* <input onChange={(e) => setarea(e.target.value)} id="inputtextareaselectbutton" placeholder="example Gulshan e hadeed or majeed colony or baldia town" type="text" class="form-control-registration" /> */}
                        <select className="selectlist2" onChange={(e) => setarea(e.target.value)} >
                            <option value={null} selected>Select Area</option>
                            {arealist && arealist.map((item) => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                        </select>

                    </div>
                    {area === 'out of city' &&
                        <div class="form-group-registration">
                            <div class="form-wrapper-registration">
                                <label for="">City Name</label>
                                <input onChange={(e) => setcity(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
                            </div>
                        </div>
                    }

                    <div class="checkbox">
                        <label style={{ fontSize: "13px" }} for="">
                            Are You Want To Play Cricket ? In Kamboh Welfare Anjuman Cricket Team
                            <div>
                                Yes <input defaultChecked={cricket} onChange={(e) => setcricket(!cricket)} id="inputtextareaselectbutton" className="checkboxregistration" type="checkbox" style={{ marginLeft: "10px", }} />
                            </div>
                        </label>
                    </div>




                    <div class="checkbox">
                        <label>
                            <p style={{ fontSize: "15px", textAlign: "justify" }}>
                                میں نے کمبوہ انجمن کراچی کے اغراض و مقاصد اور دستور کا بغور مطالعہ کیا ہے اور میں ان سے مکمل طور پر متفق ہوں اور عہد کرتا ہوں کہ میں ان تمام قواعد و ضوابط کی مکمل طور پر پابندی کروں گا۔ اور میں درخواست کرتا ہوں کہ مجھے کمبوہ انجمن کراچی کا رکن بنایا جائے۔
                            </p>
                            I Accept Membership Policy.
                            <span id="checkmarkRegistration" class="checkmark"></span>
                            <input id="inputtextareaselectbutton" defaultChecked={policy} onChange={(e) => setpolicy(!policy)} className="checkboxregistration" type="checkbox" style={{ marginLeft: "10px", }} />
                        </label>
                    </div>
                    <Button disabled={processing && disabled} variant="contained" color="secondary" onClick={_handleRegistration} style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold", marginTop: "30px" }} className="registration-button">
                        {!processing ?
                            <span>
                                Register
                            </span>
                            :
                            <span >
                                <PropagateLoader color="red" width={100}
                                />
                            </span>
                        }

                    </Button>
                    <div style={{ marginTop: "50px" }}>
                        <label style={{ fontSize: "13px" }} for="">
                            Already have a account? <Button variant="contained" color="primary" onClick={() => history.push('/login')} style={{ fontFamily: `${gilroySemibold}`, fontWeight: "bold", width: "20px", height: "20px", fontSize: "8px" }} className="login-button">Login</Button>
                        </label>
                    </div>
                </div>
            </div>
        </div>



    </Layout>
}


