export const colors = {
  primaryColor: '#000',
  secondaryColor: '#fff',
  whiteColor:"#ffffff",
  redColor:"red",
  mainColor: "#F31453",
  primaryColor: "#ffffff",
  secondaryBG:'#313140',
  secondaryColor: "#2b2b35",
  activeState: "red",
  borderColor:'#4a4a4a',
  gray: {
    10: 'rgba(112,112,112,0.1)',
    50: 'rgba(112,112,112,0.5)',
    100: '#707070',
  },
}
