


import React, { useEffect } from "react";
import styled from "styled-components";
import { colors, Media } from "./theme";
import { Container, Col } from "react-bootstrap";
import { gilroySemibold, gilroyBold, sourceSansPro, gilroyMediumItalic, gilroyExtrabold } from "./theme/fonts";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import haneef from "../assets/images/haneef.jpg";
import ibrahim from "../assets/images/ibrahim.jpg";
import saleem from "../assets/images/saleem.jpg";
import ilyas from "../assets/images/ilyas.jpg";
import qaziaslam from "../assets/images/qaziaslam.jpg";
import zafar from "../assets/images/zafar.jpg";

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Avatar from '@material-ui/core/Avatar';
import clsx from "clsx";
import { useState } from "react";
import Card1 from "./card1"
import Card2 from "./card2"
import Card3 from "./card3"
import Fade from 'react-reveal/Fade';



const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        border: "2px solid red"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },

    media: {
        height: 140,
    },
    large: {
        width: theme.spacing(16),
        height: theme.spacing(16),
    },
}));
export default function Homesection4(props) {
    const classes = useStyles();
    const [card1, setcard1] = useState(true)
    const [card2, setcard2] = useState(false)
    const [card3, setcard3] = useState(false)


    const [index, setindex] = useState(2)
    const [presidents, setpresidents] = useState([

        { name: "Qazi M aslam Kamboh Marhoom", src: qaziaslam, d: "Former President", desc: "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica" },
        { name: "Zafar Iqbal Kamboh Marhoom", src: zafar, d: "Former President", desc: "Zafar Iqbal was a kamboh welfare anjuman karachi member.after the death of former president QAZI ASLAM KAMBOH He was new president of Kamboh welfare anjuman karachi from 2020 to 2021 but with the pleasure of ALLAH he also passed away on 19 july 2021."},
        { name: "Haneef Kamboh", src: haneef, d: "Current President", desc: "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica" }

    ])



    const goToNext = () => {
        if (index === 2) {
            setindex(2);
        }
        else {
            setindex(index + 1 % presidents.length);
        }
    };
    const goToPrev = () => {
        if (index !== 0) {
            setindex(index - 1 % presidents.length);

        }
    };
    const moreMethod = (value) => {
        var e = document.getElementById("CARD2");
        e.scrollIntoView({ behavior: "smooth" });
        setcard2(true)
        if (card2 === true) {
            var e = document.getElementById("CARD3");
            e.scrollIntoView({ behavior: "smooth" });
            setcard3(true)
        }
    };

    const lessMethod = (value) => {
        var e = document.getElementById(value);
        e.scrollIntoView({ behavior: "smooth" });
        setcard2(false)
        setcard3(false)
    };
  

    return (
        <div id="CARD1">
            <Title1 >
                MEET WITH OUR
            </Title1>
            <Title2>
                LEADERSHIP
            </Title2>
            <Fade left >
            <div>
                {card1 && <Card1 />}
            </div>
            <Fade>
            <div id="CARD2">
                {card2 && <Card2 />}
            </div>
            </Fade>
           <Fade>
           <div id="CARD3">
                {card3 && <Card3 />}
            </div>
           </Fade>
          

            </Fade>
        







            <div style={{ height: "50px", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", paddingRight: "50px" }}>
                {card2 === true && card3 === true
                    ?
                    <Button onClick={()=>lessMethod('CARD1')} variant="contained" color="primary">
                        Less
                    </Button>

                    :

                    <Button  onClick={moreMethod} variant="contained" color="primary">
                        More
                    </Button>
                }

            </div>
        </div>

    );
}
const Wrapper = styled.div`
width:100%;
display: flex;
${Media("xlscreens")} {
  height: 100%;
}
${Media("tablet")} {
}
`;

const Title1 = styled.div`
width:100%;
display:flex;
justify-content:center;
padding-top:50px;
letter-spacing: -1px;
color:#9d9d9d;
font-weight:bold;
margin-top:6%;
font-family: ${gilroyMediumItalic} !important ;

${Media("xlscreens")} {
  height: 4vw;
}
${Media("tablet")} {
}
`;

const Title2 = styled.div`
width:100%;
display:flex;
justify-content:center;
font-family: ${gilroyExtrabold} !important ;
font-weight:bold;
line-height: -20px;
letter-spacing: -1.5px;
${Media("xlscreens")} {
  font-size: 4.166vw;
  margin: 0.2vw 0;
}
${Media("tablet")} {
  font-size: 38px;
  margin: 20px 0;
  br {
  }
}
`;

const Radiusdiv = styled.div`
 background-color:#f78d15;
 display:flex;
 justify-content:center;
 align-items:center;
 border-radius:50px;
 width:100px;
 height:100px;
font-family: ${gilroyExtrabold} !important ;
font-size:12px;
color:#ffffff;
font-weight:bold;
border:4px solid red;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const RadiusMain = styled.div`
 display:flex;
 justify-content:space-evenly;
 align-items:center;
 width:100%;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const Heading = styled.div`
font-size:13px;
font-weight:bold;
font-family: ${gilroyExtrabold} !important ;
margin-top:20px;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;







const CustomCol = styled(Col)`

margin-top:70px;

`;









