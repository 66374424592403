import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Fade from 'react-reveal/Fade'
import logo from "../assets/images/logo.jpg"
import { useHistory } from "react-router-dom";

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import styled from 'styled-components'
import { colors, Media } from "../components/theme";

import './footer.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: "#56574f",
    borderBottom: "1px solid grey",
    paddingBottom: "30px",
    paddingTop: "30px"
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

function Footer() {
  const classes = useStyles()
  const history = useHistory();

  return (
    <div class="footer-dark">
      <footer>
        <div class="container">
          <div class="row">
        


          <div class="col-md-6">
              <Heading style={{color:"#e98616"}}>FOR MANUAL DONATION</Heading>
              <p class="size3">Account # 02310060002218.Faisal Bank Gulzar-e-Hijri karachi.</p>

              <Heading style={{color:"#e98616"}}>E-MAIL</Heading>
              <p class="size3">kambohwelfareanjumankarachi@gmail.com</p>

              <Heading style={{color:"#e98616"}}>FACEBOOK</Heading>
              <p class="size3"> www.facebook.com/kwakp</p>

              <Heading style={{color:"#e98616"}}>WEB</Heading>
              <p class="size3">www.kamboh.com.pk</p>
            </div>


            <div class="col-sm-6 col-md-3 ">
              <Heading style={{color:"#e98616"}}>ADDRESS</Heading>
              <ul>
                <p class="size3">PLOT NO. C-228 SECTOR - 2 AHSANABAD MAIN SOUN MIYANI ROAD SCHEME NO. 33 KARACHI</p>
              </ul>
            </div>
          

            <div class="col-sm-6 col-md-3 item mobilelinks" id="mobilelinks">
              <Heading style={{color:"#e98616"}}>IMPORTANT LINKS</Heading>
              <ul style={{cursor:"pointer"}}>
                <li id="MontserratRegular" class="size" onClick={() => history.push('/')}><ArrowRightIcon/>HOME</li>
                <li id="MontserratRegular" class="size" onClick={() => history.push('/about')}><ArrowRightIcon/>ABOUT</li>
                <li id="MontserratRegular" class="size" onClick={() => history.push('/members')}><ArrowRightIcon/>MEMBERS</li>
                <li id="MontserratRegular" class="size" onClick={() => history.push('/gallery')}><ArrowRightIcon/>GALLERY</li>
                <li id="MontserratRegular" class="size" onClick={() => history.push('/contact')}><ArrowRightIcon/>CONTACT</li>
              </ul>
            </div>
            
            <div  class="col item social"><a href="https://www.facebook.com/kwakp/"><i  class="icon ion-social-facebook border2"><FacebookIcon /></i></a><a href=""><i class="icon ion-social-twitter border2"><InstagramIcon /></i></a><a href="#"><i class="icon ion-social-snapchat border2"><TwitterIcon /></i></a><a href="#"><i class="icon ion-social-instagram border2"><LinkedInIcon /></i></a></div>
          </div>
          <p class="copyright2">KAMBOH WELFARE ANJUMAN KARACHI © 2021</p>
          <p class="copyright3">BUILD BY : ZAKAMBOH</p>
          <p class="copyright3">Developer Contact : 03312380673</p>
        </div>
      </footer>
    </div>
  )
}

const Heading = styled.h3`

${Media("xlscreens")} {

}
${Media("tablet")} {
      display:flex;
      justify-content:center;
}
`;





export default Footer
