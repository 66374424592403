import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../components/theme";
import Donation from "../../assets/images/donation.png"
import { useStateValue } from '../../components/StateProvider'
import { Link, useHistory, useLocation } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { BackServer } from "../../Services"
import axios from 'axios'
import './mydonations.css'
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        marginTop: "130px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Myprofile() {
    const classes = useStyles();
    const history = useHistory();

    const [{ members, membersDonation, getmembers, membershipfees }, dispatch] = useStateValue();
    const [total, settotal] = useState('')

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        if (!members) {
            history.push('/')
        }


        if (members) {

            axios
                .post(`${BackServer}/data/getMydonation/${members._id}`)
                .then((res) => {

                    dispatch({
                        type: "GETMEMBERSDONATION",
                        payload: res.data.MEMBERDONATION
                    })
                })
        }

        if (members) {

            axios
                .post(`${BackServer}/data/getmemberWithPaidMembershipStatus/${members._id}`)
                .then((res) => {

                    dispatch({
                        type: 'MEMBERSHIPFEES',
                        payload: res.data.getmemberWithPaidMembershipStatusVerified
                    })
                })
        }


        axios
            .get(`${BackServer}/data/getAllMembers`)
            .then((res) => {
                dispatch({
                    type: "GET_MEMBERS",
                    payload: res.data.allmembers
                })
            })


    }, [])


    const get_total = () => {
        var rr = membersDonation
        var sum = 0
        for (var i = 0; i < rr.length; i++) {
            var ts = sum + + rr[i].amount

            sum = ts
        }

        settotal(sum)
    }


    return <Layout>



        <div class="container" style={{ marginTop: "130px", marginBottom: "300px" }}>


            <TableContainer style={{ marginTop: "150px", marginBottom: "100px" }} >
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ color: "white" }} >
                            <TableCell className={classes.tablecell} >Date</TableCell>
                            <TableCell className={classes.tablecell} align="right">Amount</TableCell>
                            <TableCell className={classes.tablecell} align="right">transaction</TableCell>
                            <TableCell className={classes.tablecell} align="right">Purpose</TableCell>
                            <TableCell className={classes.tablecell} align="right">comment</TableCell>




                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {/* {members && members.paidmembershipfeesstatus === "verified" && */}

                        {membershipfees && membershipfees.map((member, i) => (

                            member.paidmembershipfeesstatus === "verified" &&
                            <TableRow >

                                <TableCell className={classes.tablecell} >{member.paidmembershipfeesdate}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.paidmembershipfees}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.paidmembershipfeestransactionid}</TableCell>
                                <TableCell className={classes.tablecell} align="right">Membership Fees</TableCell>
                                <TableCell className={classes.tablecell} align="right">..</TableCell>

                            </TableRow>


                        ))}

                        {membershipfees && membershipfees.map((member, i) => (

                            member.paidregistrationfeesstatus === "verified" &&
                            <TableRow >

                        
                                <TableCell className={classes.tablecell} >{member.paidregistraionfeesdate}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.registrationfees}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.paidregistrationfeestransactionid}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{'registration fees'}</TableCell>
                                <TableCell className={classes.tablecell} align="right">..</TableCell>



                            </TableRow>


                        ))}





                        {membersDonation && membersDonation.map((item, i) => (
                            item && item.donationStatus && item.donationStatus === "approved" &&


                            <TableRow key={i} style={{ cursor: "pointer" }}>
                                <TableCell className={classes.tablecell} component="th" scope="row">
                                    {item.date}
                                </TableCell>
                                <TableCell className={classes.tablecell} align="right">{item.amount}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{item.transactionid}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{item.purpose}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{item.comment}</TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

















            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "25px", paddingRight: "120px" }}>
                <Button variant="contained" color="primary" onClick={get_total}>
                    <TableCell className={classes.tablecell} align="right">{total}</TableCell>
                                               
                    GET TOTAL
                </Button>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "15px", paddingRight: "120px",color:"red",fontWeight:"bold",fontSize:"12px" }}>

                registration and membership fees not include in total</div>

        </div>
    </Layout>

}


