


import React from "react";
import styled from "styled-components";
import { colors, Media } from "./theme";
import { Container, Col } from "react-bootstrap";
import Plot from "../assets/images/plot.jpg";
import { gilroySemibold, gilroyBold, sourceSansPro, gilroyMediumItalic, gilroyExtrabold } from "./theme/fonts";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Grid from '@material-ui/core/Grid'
import { Link, useHistory, useLocation } from "react-router-dom";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
});
export default function Homesection3(props) {
    const classes = useStyles();
    const history = useHistory();


    const urlParam = window.location.pathname;


    return (
        <div>
            <Title1>
                HELP US TO ACHIEVE OUR GOAL
            </Title1>
            <Title2>
                URGENT CAUSES
            </Title2>

            <Wrapper>


                <Grid container spacing={3}>
                    <Grid item item xs={0} sm={1}>


                    </Grid>
                    <Grid item item xs={0} sm={1}>


                    </Grid>
                    <Grid item xs={12} sm={8}>

                        <Fade bottom >

                            <RadiusMain>
                                <div onClick={()=>history.push('/donate')} style={{cursor:"pointer"}}>
                                    <Radiusdiv>
                                        DONATOR
                                    </Radiusdiv>
                                    <Heading>
                                        BECOME A DONATOR
                                    </Heading>
                                </div>
                                <div onClick={()=>history.push('/membership')} style={{cursor:"pointer"}}>
                                    <Radiusdiv>
                                        MEMBER
                                    </Radiusdiv>
                                    <Heading>
                                        BECOME A MEMBER
                                    </Heading>
                                </div>
                                <div onClick={()=>history.push('/login')} style={{cursor:"pointer"}}>
                                    <Radiusdiv>
                                        LOGIN
                                    </Radiusdiv>
                                    <Heading>
                                        If Member ? Login
                                    </Heading>
                                </div>
                            </RadiusMain>
                        </Fade>

                    </Grid>



                    <Grid item item xs={0} sm={1}>


                    </Grid>
                    <Grid item item xs={0} sm={1}>


                    </Grid>

                </Grid>

            </Wrapper>
        </div>

    );
}
const Wrapper = styled.div`
width:100%;
display: flex;
${Media("xlscreens")} {
  height: 100%;
}
${Media("tablet")} {
}
`;

const Title1 = styled.div`
width:100%;
display:flex;
justify-content:center;
padding-top:50px;
letter-spacing: -1px;
color:#9d9d9d;
font-weight:bold;
margin-top:6%;
font-family: ${gilroyMediumItalic} !important ;

${Media("xlscreens")} {
  height: 4vw;
}
${Media("tablet")} {
}
`;

const Title2 = styled.div`
width:100%;
display:flex;
justify-content:center;
font-family: ${gilroyExtrabold} !important ;
font-weight:bold;
line-height: -20px;
letter-spacing: -1.5px;
${Media("xlscreens")} {
  font-size: 4.166vw;
  margin: 0.2vw 0;
}
${Media("tablet")} {
  font-size: 38px;
  margin: 20px 0;
  br {
  }
}
`;

const RadiusMain = styled.div`
 display:flex;
 justify-content:space-evenly;
 align-items:center;
 width:100%;
 margin-top:70px;
${Media("xlscreens")} {

}
${Media("tablet")} {
   margin-top:50px;
 
         
}
`;

const Radiusdiv = styled.div`
 background-color:#f78d15;
 display:flex;
 justify-content:center;
 align-items:center;
 border-radius:50px;
 width:100px;
 height:100px;
font-family: ${gilroyExtrabold} !important ;
font-size:12px;
color:#ffffff;
font-weight:bold;
border:4px solid red;
${Media("xlscreens")} {

}
${Media("tablet")} {

}
`;

const Heading = styled.div`
font-size:13px;
font-weight:bold;
font-family: ${gilroyExtrabold} !important ;
margin-top:20px;
${Media("xlscreens")} {

}
${Media("tablet")} {
    margin-top:15px;
    padding-left:15px;
    font-size:8px;

}
`;














