




import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useStateValue } from '../../../components/StateProvider'

import Paper from '@material-ui/core/Paper';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { BackServer } from "../../../Services"

import axios from 'axios'
import ClearIcon from '@material-ui/icons/Clear';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
        backgroundColor: "black",
        color: "white",
    },

    tablecell: {
        color: "white",
    },
});





export default function MembersRequest() {
    const classes = useStyles();
    const history = useHistory()

    const [{ members, getmembers, updatedmember }, dispatch] = useStateValue()
    const [userdataPopup, setuserdataPopup] = useState(false)
    const [url, seturl] = useState('')
    const [title, settitle] = useState('')
    const [rate, setrate] = useState('')
    const [desc, setdesc] = useState('')
    const [trackingid, settrackingid] = useState('')
    const [status, setstatus] = useState('')
    const [postid, setpostid] = useState('')
    const [deliveredDate, setdeliveredDate] = useState('')




    useEffect(() => {

        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        var ADMIN = JSON.parse(localStorage.getItem('ADMIN'))
        if (!ADMIN) {
            history.push('/Admin')
        }


        var Members = JSON.parse(localStorage.getItem('members'))

        if (Members) {
            dispatch({
                type: 'SET_MEMBER',
                payload: Members,
            })
        }


        axios
            .get(`${BackServer}/data/getAllMembers`)
            .then((res) => {
                dispatch({
                    type: "GET_MEMBERS",
                    payload: res.data.allmembers
                })
            })

    }, [])



    // const MemberApproved = (user) => {
    //     var user = {
    //         userID: user._id
    //     }
    //     axios
    //         .post(`${BackServer}/data/updatemember`, user)
    //         .then((res) => {
    //             if (res && res.data && res.data.success) {
    //                 window.location.reload();
    //             }
    //         })
    // }


    return (
        <div>


            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ color: "white" }} >
                            <TableCell className={classes.tablecell} >Name</TableCell>
                            <TableCell className={classes.tablecell} align="right">Fathername</TableCell>
                            <TableCell className={classes.tablecell} align="right">Email</TableCell>
                            <TableCell className={classes.tablecell} align="right">business</TableCell>
                            <TableCell className={classes.tablecell} align="right">Mobile</TableCell>
                            <TableCell className={classes.tablecell} align="right">address</TableCell>
                            <TableCell className={classes.tablecell} align="right">Status</TableCell>
                            {/* <TableCell className={classes.tablecell} align="right">Action</TableCell> */}



                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getmembers && getmembers.map((member, i) => (
                            member.status !== "approved" &&

                            <TableRow onClick={() => history.push({ pathname: '/adminmemberapprovaldetail', state: member })} key={i} style={{ cursor: "pointer" }}>
                                <TableCell className={classes.tablecell} component="th" scope="row">
                                    {member.firstname}
                                </TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.fathername}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.email}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.business}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.mobile}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.caddress}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{member.status}</TableCell>
                                {/* <TableCell className={classes.tablecell} align="right"><button style={{ height: "40px", width: "60px", fontSize: "9px", backgroundColor: "red", color: "white" }} onClick={() => MemberApproved(member)}>APPROVE</button></TableCell> */}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
}


















