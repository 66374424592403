import  React ,{useEffect}  from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useHistory, useLocation } from "react-router-dom";

import "./adminheader.css"
export default function TemporaryDrawer() {
    const history = useHistory();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const handleAuthenticaton = () => {
        var logout=localStorage.removeItem("ADMIN");
        if(logout){
          history.push('/Admin')
    
        }
      }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List style={{marginTop:"50px"}}>

                

                <ListItem button onClick={()=>history.push('/memberrequests')} >
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Members Requests"} />
                </ListItem>
                <Divider />
              
                <ListItem button  onClick={()=>history.push('/memberadmin')}>
                    <ListItemIcon>
                        <InboxIcon /> ]
                    </ListItemIcon>
                    <ListItemText primary={"Members"} />
                </ListItem>
                <Divider />

                <ListItem button  onClick={()=>history.push('/postjobadmin')}>
                    <ListItemIcon>
                        <InboxIcon /> ]
                    </ListItemIcon>
                    <ListItemText primary={"post a job"} />
                </ListItem>

                <ListItem button  onClick={handleAuthenticaton}>
                    <ListItemIcon>
                        <InboxIcon /> ]
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItem>

            </List>
            <Divider />
           
        </Box>
    );

    return (
        <div style={{ height: "70px", display: "flex", alignItems: "center", paddingLeft: "30px", backgroundColor: "#ffffff" }}>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}