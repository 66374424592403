import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../components/theme";
import Donation from "../../assets/images/donation.png"
import { useStateValue } from '../../components/StateProvider'
import { Link, useHistory, useLocation } from "react-router-dom";
import { BackServer } from "../../Services"
import axios from 'axios'
import './profile.css'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "130px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Myprofile() {
    const classes = useStyles();
    const history = useHistory();

    const [{ members, getmembers }, dispatch] = useStateValue();

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        if (!members) {
            history.push('/')
        }


        var Members = JSON.parse(localStorage.getItem('members'))


        if (Members) {
            dispatch({
                type: 'SET_MEMBER',
                payload: Members,
            })
        }





    }, [])

    const signoutMethod = () => {

        localStorage.removeItem("members");

        dispatch({
            type: "SET_MEMBER",
            payload: null,
        });

        history.push("/");

    };

    return <Layout>

        {
            members && members.status === "approved" ?
                <div class="container" style={{ marginTop: "130px" }}>
                    <div class="main-body">


                        <div class="row gutters-sm">
                            <div class="col-md-4 mb-3">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <img src={members && members.imageUrl} alt={members.membershipcategory} class="rounded-circle" width="150" />

                                            <div class="mt-3">
                                                <h4>{members && members.firstname}</h4>
                                                <p class="text-secondary mb-1">{members && members.business}</p>
                                                <p class="text-muted font-size-sm">{members && members.caddress}</p>
                                                <button onClick={signoutMethod} class="btn btn-primary">LOGOUT</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="card mt-3">
                                    <ul class="list-group list-group-flush">

                                        <li style={{ cursor: "pointer" }} onClick={() => history.push("/mydonation")} class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                            <h6 class="mb-0"><img src={Donation} width="30px" height="30px" /> My Donations</h6>
                                        </li>


                                        <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                            <h7 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook mr-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>Facebook</h7>
                                            <a href="https://www.facebook.com/kwakp/photos/?ref=page_internal" class="text-secondary">Link</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <h6 class="mb-0">Full Name</h6>
                                            </div>
                                            <div class="col-sm-9 text-secondary">
                                                {members && members.firstname + "," + members.fathername}
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <h6 class="mb-0">Email</h6>
                                            </div>
                                            <div class="col-sm-9 text-secondary">
                                                {members && members.email}
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <h6 class="mb-0">Mobile</h6>
                                            </div>
                                            <div class="col-sm-9 text-secondary">
                                                {members && members.mobile}

                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <h6 class="mb-0">Business</h6>
                                            </div>
                                            <div class="col-sm-9 text-secondary">
                                                {members && members.business}
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <h6 class="mb-0">Address</h6>
                                            </div>
                                            <div class="col-sm-9 text-secondary">
                                                {members && members.caddress}

                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <h6 class="mb-0">Area</h6>
                                            </div>
                                            <div class="col-sm-9 text-secondary">
                                                {members && members.area}

                                            </div>
                                        </div>
                                        <hr />

                                        <div class="row">
                                            <div class="col-sm-3">
                                                <h6 class="mb-0">Membership Category</h6>
                                            </div>
                                            <div class="col-sm-9 text-secondary">


                                                {members && members.membershipcategory}
                                                <br />
                                                membership fees : {members && members.membershipfees}
                                                <br />

                                                Registration fees : {members && 500}
                                                <br />

                                                paid Registration fees : {members && members.registrationfees}


                                            </div>
                                        </div>
                                        <hr />

                                        <div class="row">
                                            <div class="col-sm-3">
                                                <h6 class="mb-0">Cnic</h6>
                                            </div>
                                            <div class="col-sm-9 text-secondary">
                                                {members && members.cnic}

                                            </div>
                                        </div>
                                        <hr />

                                        <div class="row">
                                            <div class="col-sm-3">
                                                <h6 class="mb-0">Blood Group</h6>
                                            </div>
                                            <div class="col-sm-9 text-secondary">
                                                {members && members.bloodgroup}

                                            </div>
                                        </div>
                                        <hr />



                                        <div class="row">
                                            <div onClick={() => alert("work in progress")} class="col-sm-12">
                                                <a class="btn btn-info " >Apply for membership card</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>





                            </div>
                        </div>

                    </div>
                </div>
                :

                <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", marginTop: "100px", alignItems: "center" }}>
                    Your request is not approve yet or may be your account ban by admin due to unpaid membership monthly fees or any other reason.
                </div>

        }



    </Layout>
}


