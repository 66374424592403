import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Layout from 'components/layout'
import styled from 'styled-components'
import { colors, Media } from "../../../components/theme";
import Donation from "../../../assets/images/donation.png"
import { useStateValue } from '../../../components/StateProvider'
import { Link, useHistory, useLocation } from "react-router-dom";
import { BackServer } from "../../../Services"
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "130px",
    },
    imageList: {
        width: 1000,
        height: 750,
        marginBottom: '100px',
        objectFit: "contain"
    },
}));


export default function Adminmemberdetail(props) {
    const [memberdetail, setmemberdetail] = useState('')
    const [membercard, setmembercard] = useState(false)

    const classes = useStyles();
    const history = useHistory();
    const [{ members }, dispatch] = useStateValue();

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        var ADMIN = JSON.parse(localStorage.getItem('ADMIN'))
        if (!ADMIN) {
            history.push('/Admin')
        }
        // alert(JSON.stringify(history.location.state))
        setmemberdetail(history.location.state)


    }, [])

    const signoutMethod = () => {

        localStorage.removeItem("members");

        dispatch({
            type: "SET_MEMBER",
            payload: null,
        });

        history.push("/");

    };

    const Memberreject = (Userid) => {

        axios
            .delete(`${BackServer}/data/deleteMemberRequest/${Userid}`,)
            .then((res) => {

                if (res.data.suc === true) {
                    history.push('/memberadmin')
                }
            })

    }


    const MemberActivate = (Userid) => {


        var user = {
            userID: Userid
        }
        axios
            .post(`${BackServer}/data/activate`, user)
            .then((res) => {
                if (res && res.data && res.data.success) {
                    history.push('/memberadmin')
                }
            })
    }

    const MemberDeActivate = (Userid) => {


        var user = {
            userID: Userid
        }
        axios
            .post(`${BackServer}/data/deactivate`, user)
            .then((res) => {
                if (res && res.data && res.data.success) {
                    history.push('/memberadmin')
                }
            })
    }


    const Membercard = (membercarddetail) => {
           
        setmembercard(true)
            
    }

    

    return (

        <div class="container" style={{ marginTop: "70px" }}>
               
            <div class="main-body">
                

                <div class="row gutters-sm">
                    <div class="col-md-4 mb-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex flex-column align-items-center text-center">
                                    <img src={memberdetail && memberdetail.imageUrl} alt="Admin" class="rounded-circle" width="150" />

                                    <div class="mt-3">
                                        <h4>{memberdetail && memberdetail.firstname}</h4>
                                        <p class="text-secondary mb-1">{memberdetail && memberdetail.business}</p>
                                        <p class="text-muted font-size-sm">{memberdetail && memberdetail.caddress}</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="card mt-3">
                            <ul class="list-group list-group-flush">

                                <li style={{ cursor: "pointer" }} onClick={() => history.push({ pathname: '/checkallmemberdonations', state: memberdetail && memberdetail })} class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0"><img src={Donation} width="30px" height="30px" /> Member Donation</h6>
                                </li>


                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h7 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook mr-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>Facebook</h7>
                                    <a href="https://www.facebook.com/kwakp/photos/?ref=page_internal" class="text-secondary">Link</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-3">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Full Name</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.firstname + "," + memberdetail.fathername}
                                    </div>
                                </div>
                                <hr />


                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Membership Category</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">


                                        {memberdetail && memberdetail.membershipcategory}
                                        <br />
                                        membership fees : {memberdetail && memberdetail.membershipfees}
                                        <br />

                                        Registration fees : {memberdetail && 500}
                                        <br />

                                        paid Registration fees : {memberdetail && memberdetail.registrationfees}


                                    </div>
                                </div>

                                <hr />

                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Email</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.email}
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Mobile</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.mobile}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Business</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.business}
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Current Address</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.caddress}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Permanent Address</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.paddress}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Office/Shop Address</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.oaddress}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Age</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.age}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Qualification</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.qualification}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Cnic</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.cnic}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Area</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.area}

                                    </div>
                                </div>
                                <hr />

                                {memberdetail && memberdetail.city &&

                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">City</h6>
                                        </div>
                                        <div class="col-sm-9 text-secondary">
                                            {memberdetail && memberdetail.city}

                                        </div>
                                    </div>
                                }

                                <hr />


                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Sportman/Cricketer?</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.cricket === "true" ? "Yes" : "No"}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Accept Term And Condition</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.policy === "true" ? "Yes" : "No"}

                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Blood Group</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {memberdetail && memberdetail.bloodgroup}

                                    </div>
                                </div>
                                <hr />

                                <div class="row">
                                    <div style={{ marginTop: "20px" }} onClick={() => Memberreject(memberdetail && memberdetail._id)} class="col-sm-3">
                                        <a style={{ color: "white", fontSize: "10px" }} class="btn btn-danger">DELETE MEMBERSHIP</a>
                                    </div>


                                    {memberdetail && memberdetail.activateStatus === 'deactivate' &&

                                        <div style={{ marginTop: "20px" }} onClick={() => MemberActivate(memberdetail && memberdetail._id)} class="col-sm-3">
                                            <a style={{ color: "white", fontSize: "12px" }} class="btn btn-info">ACTIVATE</a>
                                        </div>
                                    }

                                    {memberdetail && memberdetail.activateStatus === 'activate' &&

                                        <div style={{ marginTop: "20px" }} onClick={() => MemberDeActivate(memberdetail && memberdetail._id)} class="col-sm-3">
                                            <a style={{ color: "white", fontSize: "10px" }} class="btn btn-danger">DEACTIVATE</a>
                                        </div>
                                    }

                                    <div style={{ marginTop: "20px" }} onClick={() => history.push({ pathname: '/membershipcard', state:memberdetail })} class="col-sm-3">
                                        <a style={{ color: "white", fontSize: "10px" }} class="btn btn-info">ISSUE MEMBERSHIP CARD</a>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    )
}


