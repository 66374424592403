import React,{useEffect} from 'react'
import styled from 'styled-components'
import Layout from 'components/layout'
import Homesection1 from "components/homepageSection1"
import Homesection2 from "components/homepageSection2"
import Homesection3 from "components/homepageSection3"
import Homesection4 from "components/homepageSection4"
import Homesection5 from "components/homepageSection5"

const Home = () => {

  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0


}, [])
  return <Layout>
    <Homesection1 />
    <Homesection2 />
    <Homesection3 />
    <Homesection4 />
    <Homesection5/>
  </Layout>
}
export default Home
