// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import firebase from "firebase";


// const firebaseConfig = {
//     apiKey: "AIzaSyAXBS3tatWQOxH7lgZwaByg649rm0HcuJw",
//     authDomain: "iconwood-website.firebaseapp.com",
//     projectId: "iconwood-website",
//     storageBucket: "iconwood-website.appspot.com",
//     messagingSenderId: "435259122656",
//     appId: "1:435259122656:web:eac96322d0c8e4011b4185",
//     measurementId: "G-YTZZZD06KC"
//   };

  const firebaseConfig = {
    apiKey: "AIzaSyAO6yG3u8iIqvEkZhSaJXuHgRFe7MaBFb8",
    authDomain: "practice-261818.firebaseapp.com",
    projectId: "practice-261818",
    storageBucket: "practice-261818.appspot.com",
    messagingSenderId: "809644764377",
    appId: "1:809644764377:web:c328fd1ff06c9a54c522c8",
    measurementId: "G-57B3CS1E79"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const auth = firebase.auth();
  const storage = firebase.storage();
  const  database = firebase.database()
  
  export { auth,storage,database };